import React, { useState, useEffect } from "react";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";

const ComprehensiveAssessment = ({ updateDatafn, selectedPatient, id }) => {
  const [data, setData] = useState("");
  const [mobility, setMobility] = useState("");
  const onClickFn = () => {
    updateDatafn({ PatientId: id, ComprehensiveAssessment: data });
  };
  const onClickMobility = () => {
    if (mobility === "") return;
    updateDatafn({ PatientId: id, Mobility: mobility });
  };
  useEffect(() => {
    if (selectedPatient) {
      setData(selectedPatient?.ComprehensiveAssessment);
      setMobility(selectedPatient?.Mobility);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Comprehensive Assessment"}
        children={
          <AddDetailGeneral data={selectedPatient}>
            <>
              <p className="displayTitleGnrl">Comprehensive Assessment</p>
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Comprehensive Assessment"}
                iconsList={[
                  {
                    value: "mingcute:save-line",
                    onClickFn: () => onClickFn(),
                  },
                ]}
              />{" "}
              <div style={{ marginLeft: "1rem" }}>
                <select
                  style={{
                    fontSize: "1.4rem",
                    width: "30%",
                    marginRigth: "1rem",
                  }}
                  className="display-element-Gnrl"
                  value={mobility}
                  onChange={(event) => setMobility(event.target.value)}
                >
                  <option value="" disabled hidden>
                    Select Mobility
                  </option>{" "}
                  {["High", "medium", "Low"].map((el) => (
                    <option value={el}>{el} </option>
                  ))}
                </select>
                <button
                  style={{
                    marginLeft: "1rem",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: "#7a5dda",
                    border: "none",
                    padding: "8px 10px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={onClickMobility}
                >
                  Save Mobility
                </button>
              </div>
              <div className="display-sec-Gnrl">
                <div className="display-content-Gnrl display-content-fullwidth">
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data}
                      onChange={(event) => setData(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>{" "}
          </AddDetailGeneral>
        }
      ></DropDownContent>
    </div>
  );
};

export default ComprehensiveAssessment;

import React, { useEffect, useState } from "react";
import RcmAndCcm from "./RcmAndCcm/RcmAndCcm";
import Medication from "./careteamElements/Medication";
import Symptoms from "./careteamElements/Symptoms";
import Vitals from "./careteamElements/Vitals";
import Goals from "./careteamElements/Goals";
import FamilyHistory from "./careteamElements/FamilyHistory";
import LifeStyle from "./careteamElements/Lifestyle";
import IcdCode from "./careteamElements/IcdCode.jsx";
import FloatingNotifications from "../../utilities/FloatingNotifications";
import {
  useUpdatePatientsMutation,
  useGetPatientSelectedProfileMutation,
} from "../../app/api/patientsApi";
import Prognosis from "./careteamElements/Prognosis";
import MedicalEvents from "./careteamElements/MedicalEvents";
import Barriers from "./careteamElements/Barriers";
import Allergies from "./careteamElements/Allergies";
import LifeStyleRecomentation from "./careteamElements/LifeStyleRecoments";
import OtherRecommends from "./careteamElements/OtherRecomends";
import PreventiveCareAndShedule from "./careteamElements/PreventiveCareAndSchedule";
import Immunization from "./careteamElements/Immunization";
import PatientDiagnosis from "./careteamElements/PatientDiagnosis/PatientDiagnosis";
import CareplanSidebar from "./CareplanSidebar";
import PatientDemographicsComponent from "../patientViewPage/PatientDemographicsComponent";
import Concerns from "./careteamElements/Concerns";
import PreventiveServices from "./careteamElements/PreventiveServices";
import EmergencyAndUrgentNeedsPlan from "./careteamElements/EmergencyAndUrgentNeedsPlan";
import ComprehensiveAssessment from "./careteamElements/ComprehensiveAssessment";
import ReviewofSystem from "./careteamElements/ReviewofSystem";
import { useGetPatientsOptionsQuery } from "../../app/api/patientSelctorOptionApi";
import "./careBodyComponent.scss";
const sidebarArray = [
  "Demographics",
  "Comprehensive Assessment",
  "Emergency and Urgent Needs Plan",
  "Review of System",
  "Care Team",
  "Patient Diagnosis",
  // "ICD code",
  "Medication",
  "Symptoms",
  "Prognosis",
  "Concerns",
  "Goals",
  "Family History",
  "Lifestyle",
  "Lifestyle Recommends",
  "Other Recommends",
  "Barriers",
  "Preventive care & Schedule",
  "Allergies",
  "Immunization",
  "Medical Events",
  "Vitals",
];
const CareBodyComponent = (props) => {
  const { id, selectedPatient, updateDatafn, carePlanCallFn } = props;
  const [selectedProfilefn, { data: Data }] =
    useGetPatientSelectedProfileMutation();
  const [selectedData, setSelectedData] = useState();

  const [window, setWindow] = useState("Demographics");
  const [updatePatientfn, { data: updatedData, error: errorUpdating }] =
    useUpdatePatientsMutation();
  const onClickSideBar = (value) => {
    setWindow(value);
  };
  const onClickSave = (apiName, selectedOption) => {
    let ls = apiName.split(".");
    ls.reverse();
    const res = ls.reduce((prev, curr, idx) => {
      if (!prev) {
        return { [curr]: selectedOption };
      } else {
        return { [curr]: prev };
      }
    }, null);
    updateDatafn({ PatientId: id, ...res });
  };
  const { data: fpcList } = useGetPatientsOptionsQuery();

  const FacilityList = fpcList?.FacilityList ?? [];
  const ProviderList = fpcList?.ProviderList ?? [];
  const ClinicianList = fpcList?.ClinicianList ?? [];

  const getDate = (value) => {
    if (!value) return;
    let date = `${new Date(value)}`;
    let words = date.split(" ");
    let updatedWords = words[1] + " " + words[2] + " " + words[3];
    return updatedWords;
  };
  useEffect(() => {
    if (!errorUpdating) return;
    FloatingNotifications("error", "Updation failed");
  }, [errorUpdating]);
  useEffect(() => {
    if (!id) return;
    selectedProfilefn({
      PatientId: id,
    });
  }, [id, selectedProfilefn]);
  useEffect(() => {
    if (!Data) return;
    setSelectedData(Data);
  }, [Data]);
  useEffect(() => {
    if (!updatedData) return;
    FloatingNotifications("success", "Updated successfully");
    setSelectedData(updatedData);
    carePlanCallFn({ PatientId: id });
  }, [updatedData]);
  return (
    <div className="carePlanBodySection">
      <CareplanSidebar
        window={window}
        sidebarArray={sidebarArray}
        clickfn={onClickSideBar}
      />

      {fpcList && (
        <div className="content-section">
          <div className="date-section">
            <div>
              <p className="sub">Care Plan Created Date & Time </p>
              <p className="date">{getDate(selectedPatient?.createdAt)}</p>
            </div>
            <div>
              <p className="sub">Care Plan Updated Date & Time </p>
              <p className="date">{getDate(selectedPatient?.updatedAt)}</p>
            </div>
          </div>
          {/* <Download /> */}

          {/* CARE PLAN */}
          {window === "Demographics" && (
            <PatientDemographicsComponent
              selectedData={selectedData}
              id={id}
              updatePatientfn={updatePatientfn}
              updatedData={updatedData}
            />
          )}
          {window === "Care Team" && (
            <RcmAndCcm
              selectedPatient={selectedPatient}
              onClickSave={onClickSave}
              ProviderList={ProviderList}
              ClinicianList={ClinicianList}
            />
          )}
          {window === "Comprehensive Assessment" && (
            <ComprehensiveAssessment
              id={id}
              updateDatafn={updateDatafn}
              selectedPatient={selectedPatient}
            />
          )}
          {window === "Emergency and Urgent Needs Plan" && (
            <EmergencyAndUrgentNeedsPlan
              id={id}
              updateDatafn={updateDatafn}
              selectedPatient={selectedPatient}
            />
          )}

          {window === "Review of System" && (
            <ReviewofSystem
              id={id}
              updateDatafn={updateDatafn}
              selectedPatient={selectedPatient}
            />
          )}
          {window === "Patient Diagnosis" && (
            <IcdCode
              id={id}
              updateDatafn={updateDatafn}
              selectedPatient={selectedPatient}
            />
          )}

          {/* Patient Diagnosis */}

          {/* {window === "Patient Diagnosis" && (
            <PatientDiagnosis
              id={id}
              updateDatafn={updateDatafn}
              selectedPatient={selectedPatient}
            />
          )} */}

          {/* Medication */}

          {window === "Medication" && (
            <Medication
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* Symptoms*/}

          {window === "Symptoms" && (
            <Symptoms
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* Prognosis */}

          {window === "Prognosis" && (
            <Prognosis
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* Concerns */}

          {window === "Concerns" && (
            <Concerns
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* Goals */}

          {window === "Goals" && (
            <Goals
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/*Family History  */}

          {window === "Family History" && (
            <FamilyHistory
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* LifestyleRemarks*/}

          {window === "Lifestyle" && (
            <LifeStyle
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/*Lifestyle Recommends  */}
          {window === "Lifestyle Recommends" && (
            <LifeStyleRecomentation
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/*Other Recommends  */}
          {window === "Other Recommends" && (
            <OtherRecommends
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}
          {/*Barriers */}
          {window === "Barriers" && (
            <Barriers
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/*Preventive care & Schedule */}
          {window === "Preventive care & Schedule" && (
            <PreventiveCareAndShedule
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}
          {/*Allergies*/}
          {window === "Allergies" && (
            <Allergies
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/*Immunization*/}
          {window === "Immunization" && (
            <Immunization
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}
          {/*Vitals*/}
          {window === "Vitals" && (
            <Vitals
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}

          {/* MedicalEvents */}

          {window === "Medical Events" && (
            <MedicalEvents
              updateDatafn={updateDatafn}
              id={id}
              selectedPatient={selectedPatient}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CareBodyComponent;

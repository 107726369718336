import React from "react";
import { v4 as uuid } from "uuid";

import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearchGeneral from "../../searchandselect component/SearchAndSelectGeneral";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  getCurrentDateAndTime,
  statusfinderFn,
} from "../helperfunctions";
import { useGetICDcodeMutation } from "../../../app/api/patientsApi";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const IcdCode = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [diagnosisWindow, setDiagnosisWindow] = useState("");
  const addIcdCodeDetail = () => {
    setDiagnosisWindow("addDetails");
    resetfn(diagnosis, setDiagnosis, uuid());
  };
  const [editArray, setEditArray] = useState();

  const [diagnosisArray, setDiagnosisArray] = useState([]);
  const [getICDfn, { data: ICDcodeData }] = useGetICDcodeMutation();

  const [active, setActive] = useState("Active");

  const [diagnosis, setDiagnosis] = useState({
    ICD_Code: "",
    ICD_Description: "",
    Type: "",
    RecordedDate: getCurrentDateAndTime(),
    EndDate: "",
    LastAssessmentDate: "",
    Status: true,
    Diagnosis_id: uuid(),
  });
  useEffect(() => {
    console.log(diagnosis);
  }, [diagnosis]);
  const onClickFn = () => {
    if (basicValidation(diagnosis)) {
      generalSaveButton(
        diagnosisWindow,
        setDiagnosisWindow,
        diagnosis,
        setDiagnosisArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  const [Icd, setIcd] = useState();

  const icdfn = (value) => {
    setIcd(value?.split(" ")[0]);
  };
  useEffect(() => {
    if (
      basicValidation(diagnosis) &&
      !areArraysSame(selectedPatient?.Diagnosis ?? [], diagnosisArray)
    ) {
      updateDatafn({ PatientId: id, Diagnosis: diagnosisArray });
    }
  }, [diagnosisArray]);
  useEffect(() => {
    setDiagnosisArray(selectedPatient.Diagnosis);
    // console.log(selectedPatient.Diagnosis)
  }, [selectedPatient]);

  // useEffect(() => {
  //   const now = new Date();
  //   console.log(now);
  // }, []);
  return (
    <div>
      <DropDownContent title={"Icd Code"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Icd Code"}
              iconsList={[
                { value: "ic:twotone-plus", onClickFn: addIcdCodeDetail },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    diagnosisWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setDiagnosisWindow),
                },
              ]}
            >
              <div>
                {diagnosisWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">ICD code information </p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Search by ICD Code or Description*
                          </label>
                          <SelectWithSearch
                            options={ICDcodeData}
                            valuedata={diagnosis?.["ICD_Code"]}
                            fn={generalHandleValueChanges}
                            setvalue={setDiagnosis}
                            name={"ICD_Code"}
                            decription={"ICD_Description"}
                            ApiOption={true}
                            Apicallfn={getICDfn}
                            selectedPatient={selectedPatient}
                            icdfn={icdfn}
                          />

                          <label className="display-element-Gnrl">
                            Description*
                          </label>
                          <textarea
                            className="display-element-Gnrl "
                            disabled={true}
                            // onChange={(event) =>
                            //   generalHandleValueChanges(
                            //     event,
                            //     setDiagnosis,
                            //     "ICD_Description"
                            //   )
                            // }
                            placeholder={diagnosis["ICD_Description"] ?? ""}
                            value={diagnosis?.["ICD_Description"]}
                          />
                          <label className="display-element-Gnrl">
                            Last Assessment Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={diagnosis?.LastAssessmentDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setDiagnosis,
                                "LastAssessmentDate"
                              )
                            }
                          />
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">Type*</label>
                          <select
                            className="display-element-Gnrl "
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setDiagnosis,
                                "Type"
                              )
                            }
                            value={diagnosis["Type"]}
                          >
                            <option className="default" value=""></option>
                            <option value={"Acute"}>Acute</option>
                            <option value={"Chronic"}>Chronic</option>
                          </select>
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={diagnosis?.RecordedDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setDiagnosis,
                                "RecordedDate"
                              )
                            }
                          />

                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={diagnosis?.EndDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setDiagnosis,
                                "EndDate"
                              )
                            }
                            min={diagnosis.RecordedDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient?.Diagnosis)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn't have any Diagnosis yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Diagnosis"}
                        array={diagnosisArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setDiagnosisWindow, setDiagnosis]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient?.Diagnosis
                        )}
                        dataSet={selectedPatient?.Diagnosis ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["ICD Code", "ICD_Code", "colSpan4"],
                          ["Recorded Date & Time:", "RecordedDate", "colSpan4"],
                          ["End Date & Time:", "EndDate", "colSpan4"],
                          ["ICD Description:", "ICD_Description", "colSpan4"],
                          [
                            "LastAssessmentDate:",
                            "LastAssessmentDate",
                            "colSpan4",
                          ],
                          [
                            "Assessment Summary:",
                            "AssessmentSummary",
                            "colSpan4",
                          ],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              <>
                {statusfinderFn("history", selectedPatient?.Diagnosis)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.Diagnosis)}
                    contentArray={[
                      ["ICD Code", "ICD_Code", "colSpan4"],
                      ["Recorded Date & Time:", "RecordedDate", "colSpan4"],
                      ["End Date & Time:", "EndDate", "colSpan4"],
                      ["ICD Description:", "ICD_Description", "colSpan4"],
                      ["LastAssessmentDate:", "LastAssessmentDate", "colSpan4"],
                      ["Assessment Summary:", "AssessmentSummary", "colSpan4"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default IcdCode;

import React, { useState } from "react";
import { Icon } from "@iconify/react";
import IconContainer from "../patients/Careteam/iconContainer/iconContainer";

import "../sass/activeHistoryDropDown2.scss";

function ActiveHistoryDropDown2(props) {
  const {
    children,
    iconsList,
    type,
    callList,
    clickForShedule,
    open,
    saveName,
  } = props;

  return (
    <>
      <div className="Container">
        <div className="titleSec">
          <div>
            {!callList ? (
              <IconContainer iconsList={iconsList} saveName={saveName} />
            ) : (
              <button
                className="createSheduleButton"
                onClick={() => clickForShedule()}
              >
                <Icon icon="simple-line-icons:calender" />
                <p>Create Shedule</p>
              </button>
            )}
          </div>
        </div>
        {<div className="body">{children}</div>}
      </div>
    </>
  );
}

export default ActiveHistoryDropDown2;

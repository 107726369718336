import React, { useState } from "react";
import { Icon } from "@iconify/react";
import SelectComponent from "../../../../components/SelectComponent";
import {
  useAddDeviceMutation,
  useGetDeviceDetailsMutation,
} from "../../../../app/api/rpmApi";
import "../../../../sass/activeHistoryDropDown.scss";
import CarePlanErrorMessage from "../../../Careteam/CarePlanErrorMessage";
import RpmDataDisplay from "./RpmDataDisplay";
import FloatingNotifications from "../../../../utilities/FloatingNotifications";
import LoaderComponent from "../../../../components/LoaderComponent";
import {
  generalHandleValueChanges,
  statusfinderFn,
  careplanview,
  basicValidation,
} from "../../../Careteam/helperfunctions";
import { useEffect } from "react";

function DropContainer(props) {
  const { children, iconsList, type, id } = props;
  const [showContent, setShowContent] = useState(true);
  const [editArray, setEditArray] = useState();

  const [devicesArray, setDevicesArray] = useState([]);

  const [window, setWindow] = useState("display");
  const onClickHandler = () => {
    setShowContent((prev) => !prev);
  };
  const initialState = {
    deviceProvider: "",
    deviceModel: "",
    deviceimei: "",
    effectiveDate: "",
    location: "",
    DeviceSafety: false,
    DeviceAuthentication: false,
    DeviceDemonstration: false,
    EducationMaterialSent: false,
    PatientTraining: false,
    Status: true,
  };
  const [device, setDevices] = useState({
    deviceProvider: "",
    deviceModel: "",
    deviceimei: "",
    effectiveDate: "",
    location: "",
    DeviceSafety: false,
    DeviceAuthentication: false,
    DeviceDemonstration: false,
    EducationMaterialSent: false,
    PatientTraining: false,
    Status: true,
  });
  const [addDeviceFn, { data: Patientdetails }] = useAddDeviceMutation();
  const [getAllDevicesFn, { data: AllDeviceDetails, isLoading }] =
    useGetDeviceDetailsMutation();
  const [popUpSave, setPopUpSave] = useState(false);
  const [optionDict, setOptionDict] = useState([]);
  const [devicesDetails, setdevicesDetails] = useState();

  const SaveButton = () => {
    if (!basicValidation(device)) {
      FloatingNotifications(
        "error",
        "To proceed, all fields must be filled out."
      );
      return;
    }
    setPopUpSave(true);
  };

  const onSaveConfirmed = () => {
    if (!device) return;
    if (editArray) {
      addDeviceFn({
        PatientId: id,
        ...device,
      });
      setPopUpSave(false);
      setWindow("display");
      setDevices(initialState);
      setEditArray();
      FloatingNotifications("success", "Device updated successfully");
      return;
    }

    if (!devicesDetails) {
      addDeviceFn({
        PatientId: id,
        deviceInformation: [device],
      });
    } else {
      addDeviceFn({
        PatientId: id,
        deviceInformation: [...devicesDetails, device],
      });
    }

    setPopUpSave(false);
    setDevices(initialState);
    setWindow("display");
    setEditArray();
    FloatingNotifications("success", "New Device added successfully");
  };
  const addDevice = () => {
    setShowContent(true);
    setWindow("addDetails");
  };
  const handleCheckBox = (value) => {
    setDevices((prevDevices) => ({
      ...prevDevices,
      [value]: !prevDevices[value],
    }));
  };
  const cancelfn = () => {
    setWindow("display");
    setDevices(initialState);
    setEditArray();
  };

  useEffect(() => {
    if (id) {
      getAllDevicesFn({ PatientId: id });
    }
  }, [id, getAllDevicesFn]);

  useEffect(() => {
    if (AllDeviceDetails) {
      setdevicesDetails(AllDeviceDetails);
    }
  }, [AllDeviceDetails]);
  useEffect(() => {
    if (Patientdetails) {
      setdevicesDetails(Patientdetails);
    }
  }, [Patientdetails]);

  useEffect(() => {
    if (!devicesDetails) return;

    let Model = devicesDetails?.filter((el) => el.Status === true);
    let devModel = Model?.map((el) => el.deviceModel);

    let modelOptions = [
      ["TeleRPM scale gen 2", "scale_gen2_measure"],
      ["TeleRPM BPM gen 2", "bpm_gen2_measure"],
    ];

    let updated = modelOptions.filter((el) => !devModel.includes(el[1]));
    setOptionDict(updated);
  }, [devicesDetails]);
  return (
    <>
      <LoaderComponent isLoading={isLoading} />
      {true && (
        <div className="Container dropContainer">
          {popUpSave && (
            <div className="popUpDelete">
              <div className="popupContent">
                <p>Are you sure you want to Save this object?</p>
                <div className="popupActions">
                  <button onClick={() => onSaveConfirmed()}>Save</button>
                  <button onClick={() => setPopUpSave(false)}>Cancel</button>
                </div>
              </div>
            </div>
          )}
          <div className="title">
            <div className="title_icon" onClick={onClickHandler}>
              {showContent ? (
                <Icon
                  className="icon"
                  icon="gridicons:dropdown"
                  vFlip={true}
                  width="25"
                  height="25"
                />
              ) : (
                <Icon
                  className="icon"
                  icon="gridicons:dropdown"
                  width="25"
                  height="25"
                />
              )}
              <p>{type}</p>
            </div>

            <div className="cont">
              {type === "Active Devices" && (
                <>
                  <button className="boxBtn" onClick={addDevice}>
                    <Icon
                      icon="octicon:plus-16"
                      color="white"
                      width="20"
                      height="20"
                    />
                  </button>
                  <button className="boxBtn">
                    <Icon
                      icon="mingcute:save-line"
                      color="white"
                      width="20"
                      height="20"
                      onClick={() => SaveButton()}
                    />
                  </button>
                  <button className="boxBtn" onClick={cancelfn}>
                    <Icon
                      icon="iconamoon:close-bold"
                      color="white"
                      width="20"
                      height="20"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
          {showContent && type === "Active Devices" && window === "display" && (
            <>
              {statusfinderFn("true", devicesDetails)?.length === 0 ? (
                <CarePlanErrorMessage msg="Patient doesn’t have any Devices Added yet!" />
              ) : (
                <RpmDataDisplay
                  updateDatafn={addDeviceFn}
                  name={"deviceInformation"}
                  array={devicesDetails}
                  id={id}
                  careplanview={careplanview}
                  values={[setWindow, setDevices]}
                  data={statusfinderFn("active", devicesDetails)}
                  dataSet={devicesDetails ?? []}
                  setEditArray={setEditArray}
                  contentArray={[
                    ["Device Provider:", "deviceProvider", "colSpan6"],
                    ["Device Model:", "deviceModel", "colSpan6"],
                    // ["Vital:", "", "colSpan6", true],
                    ["Effective Date:", "effectiveDate", "colSpan6"],
                  ]}
                />
              )}
            </>
          )}
          {showContent &&
            type === "Active Devices" &&
            window === "addDetails" && (
              <div className="dropContent">
                <div className="content">
                  <div className="gridComponent">
                    <div className="dropElements">
                      <p className="dropElementsLabel">Device Provider *</p>
                      <SelectComponent
                        OptionsArray={["TeleRPM"]}
                        valueData={device.deviceProvider}
                        fn={generalHandleValueChanges}
                        setvalue={setDevices}
                        name={"deviceProvider"}
                      />
                    </div>
                    <div className="dropElements">
                      <p className="dropElementsLabel">Device Model *</p>
                      <SelectComponent
                        OptionsDict={optionDict}
                        valueData={device.deviceModel}
                        fn={generalHandleValueChanges}
                        setvalue={setDevices}
                        name={"deviceModel"}
                      />
                    </div>
                    <div className="dropElements">
                      <p className="dropElementsLabel">Device IMEI *</p>
                      <input
                        type="Number"
                        value={device.deviceimei}
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setDevices,
                            "deviceimei"
                          )
                        }
                      />
                    </div>
                    <div className="dropElements">
                      <label
                        className="dropElementsLabel"
                        htmlFor="meeting-time"
                      >
                        Effective Date *
                      </label>
                      <input
                        type="datetime-local"
                        id="meeting-time"
                        value={device?.effectiveDate?.slice(0, 16)}
                        onChange={(event) =>
                          generalHandleValueChanges(
                            event,
                            setDevices,
                            "effectiveDate"
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="gridComponent">
                    <div className="dropElements">
                      <p className="dropElementsLabel">Location *</p>
                      <SelectComponent
                        OptionsArray={["Home", "Clinic"]}
                        valueData={device.location}
                        fn={generalHandleValueChanges}
                        setvalue={setDevices}
                        name={"location"}
                      />
                    </div>
                    <div className="dropElements">
                      <p className="dropElementsLabel">Competency Validation</p>
                      <div>
                        <div className="checkboxCont">
                          <input
                            onChange={() => handleCheckBox("DeviceSafety")}
                            checked={device.DeviceSafety}
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                          <label
                            htmlFor="vehicle1"
                            className="dropElementsLabel"
                          >
                            Device Safety
                          </label>
                          <br />
                        </div>
                        <div className="checkboxCont">
                          <input
                            onChange={() =>
                              handleCheckBox("DeviceAuthentication")
                            }
                            checked={device.DeviceAuthentication}
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            value="Car"
                          />
                          <label
                            htmlFor="vehicle2"
                            className="dropElementsLabel"
                          >
                            Device Authentication
                          </label>
                          <br />
                        </div>
                        <div className="checkboxCont">
                          <input
                            onChange={() =>
                              handleCheckBox("DeviceDemonstration")
                            }
                            checked={device.DeviceDemonstration}
                            type="checkbox"
                            id="vehicle3"
                            name="vehicle3"
                            value="Boat"
                          />
                          <label
                            htmlFor="vehicle3"
                            className="dropElementsLabel"
                          >
                            Device Demonstration
                          </label>
                          <br />
                        </div>
                        <div className="checkboxCont">
                          <input
                            onChange={() =>
                              handleCheckBox("EducationMaterialSent")
                            }
                            checked={device.EducationMaterialSent}
                            type="checkbox"
                            id="vehicle4"
                            name="vehicle4"
                            value="Boat"
                          />
                          <label
                            htmlFor="vehicle4"
                            className="dropElementsLabel"
                          >
                            Education Material Sent
                          </label>
                          <br />
                        </div>
                        <div className="checkboxCont">
                          <input
                            onChange={() => handleCheckBox("PatientTraining")}
                            checked={device.PatientTraining}
                            type="checkbox"
                            id="vehicle5"
                            name="vehicle5"
                            value="Boat"
                          />
                          <label
                            htmlFor="vehicle5"
                            className="dropElementsLabel"
                          >
                            Patient Training
                          </label>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="Note">
                    Note : As per the CMS guidelines, CPT 99453 (Device Setup)
                    can be billed only once per patient. It will be
                    automatically generated in the billing summary report, when
                    the patient takes readings for 16 calendar days in a month
                    for the first time.
                  </p>
                </div>
              </div>
            )}
        </div>
      )}
    </>
  );
}

export default DropContainer;

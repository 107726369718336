import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import "../../../sass/gptSec.scss";
import LoaderComponent from "../../../components/LoaderComponent";
import { useGetGptLifestylesMutation } from "../../../app/api/patientsApi";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const LifeStyleRecommendationList = [
  "Alcohol",
  "Exercise",
  "Exercise - Arthritis",
  "Fitness",
  "Healthy Eating/Diet",
  "Healthy Eating/Diet - Diabetes",
  "Healthy Eating/Diet - Hypertension",
  "Keeping Mind Active",
  "Medication",
  "Oxygen",
  "Recreational Drugs",
  "Relaxation",
  "Safety",
  "Active Sleep",
  "Smoking",
  "Social Support",
  "Stress Management",
  "Vaccination",
  "Weight",
  "Sleep",
  "Hydration",
  "Hygiene",
  "Other",
];
const LifeStyleRecomentation = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [lifestyleRecommendationsWindow, setLifestyleRecommendationsWindow] =
    useState("");
  const addLifestyleRecommendationsDetail = () => {
    setLifestyleRecommendationsWindow("addDetails");
    resetfn(lifestyleRecommendations, setLifestyleRecommendations);
  };
  const [loading, setLoading] = useState(false);

  const [getGptCodes, { data: gptData }] = useGetGptLifestylesMutation();

  const [lifestyleRecommendationsArray, setLifestyleRecommendationsArray] =
    useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");
  const [gptLifeStyles, setGptLifeStyles] = useState([]);
  const refreshGptCodes = () => {
    setLoading(true);
    getGptCodes({ PatientId: selectedPatient.PatientId });
  };
  const [lifestyleRecommendations, setLifestyleRecommendations] = useState({
    Type: "",
    RecordedDate: "",
    EndDate: "",
    Description: "",
    Status: true,
  });

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheck = (item) => {
    setSelectedItems((prev) =>
      prev.some((i) => i.Type === item.Type)
        ? prev.filter((i) => i.Type !== item.Type)
        : [...prev, item]
    );
  };
  const handleDateChange = (event, item, index) => {
    setGptLifeStyles((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, RecordedDate: event?.target?.value } : item
      )
    );
  };
  const addGptCodes = () => {
    updateDatafn({
      PatientId: id,
      LifeStyle: [...selectedPatient.LifeStyle, ...selectedItems],
    });
    setSelectedItems([]);
    getGptCodes();
    setLifestyleRecommendationsWindow("");
  };

  const onClickFn = () => {
    if (basicValidation(lifestyleRecommendations)) {
      generalSaveButton(
        lifestyleRecommendationsWindow,
        setLifestyleRecommendationsWindow,
        lifestyleRecommendations,
        setLifestyleRecommendationsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      setLifestyleRecommendationsArray(selectedPatient.LifeStyle);
      getGptCodes({ PatientId: selectedPatient.PatientId });
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (
      basicValidation(lifestyleRecommendations) &&
      !areArraysSame(selectedPatient?.LifeStyle, lifestyleRecommendationsArray)
    ) {
      updateDatafn({
        PatientId: id,
        LifeStyle: lifestyleRecommendationsArray,
      });
    }
  }, [lifestyleRecommendationsArray]);

  const renderCarePlanSection = () => {
    const isLifestyleEmpty = statusfinderFn(
      "active",
      selectedPatient?.LifeStyle
    );
    if (isLifestyleEmpty?.length === 0) {
      return (
        <CarePlanErrorMessage msg="Patient doesn’t have any Lifestyle Recommendations yet!" />
      );
    } else {
      return (
        <CarePlanDisplayGeneral
          updateDatafn={updateDatafn}
          name={"LifeStyle"}
          array={lifestyleRecommendationsArray}
          id={id}
          careplanview={careplanview}
          values={[
            setLifestyleRecommendationsWindow,
            setLifestyleRecommendations,
          ]}
          data={statusfinderFn("active", selectedPatient.LifeStyle)}
          dataSet={selectedPatient?.LifeStyle ?? []}
          setEditArray={setEditArray}
          contentArray={[
            ["Type:", "Type", "colSpan12"],
            ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
            ["End Date & Time:", "EndDate", "colSpan6"],
            ["Description:", "Description", "colSpan12"],
          ]}
        />
      );
    }
  };
  const renderAddDetailsSection = () => (
    <AddDetailGeneral data={selectedPatient}>
      <>
        <p className="displayTitleGnrl">
          Lifestyle Recommendation Informations
        </p>
        <div className="display-sec-Gnrl">
          <div className="display-content-Gnrl">
            <label className="display-element-Gnrl">Type*</label>
            <SelectWithSearch
              options={LifeStyleRecommendationList}
              valuedata={lifestyleRecommendations.Type}
              fn={generalHandleValueChanges}
              setvalue={setLifestyleRecommendations}
              name={"Type"}
            />

            <label className="display-element-Gnrl">Description*</label>
            <div className="displayDescription">
              <textarea
                className="display-element-Gnrl"
                value={lifestyleRecommendations.Description}
                onChange={(event) =>
                  generalHandleValueChanges(
                    event,
                    setLifestyleRecommendations,
                    "Description"
                  )
                }
              />
            </div>
          </div>
          <div className="display-content-Gnrl">
            <label className="display-element-Gnrl">
              Recorded Date & Time*
            </label>
            <input
              className="display-element-Gnrl"
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              value={lifestyleRecommendations.RecordedDate?.slice(0, 16) || ""}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setLifestyleRecommendations,
                  "RecordedDate"
                )
              }
            />
            <label className="display-element-Gnrl">End Date & Time</label>
            <input
              className="display-element-Gnrl"
              type="datetime-local"
              id="meeting-time"
              name="meeting-time"
              value={lifestyleRecommendations?.EndDate?.slice(0, 16) || ""}
              onChange={(event) =>
                generalHandleValueChanges(
                  event,
                  setLifestyleRecommendations,
                  "EndDate"
                )
              }
              min={lifestyleRecommendations.RecordedDate ?? ""}
            />
          </div>
        </div>
      </>
    </AddDetailGeneral>
  );
  const renderGptSection = () => {
    return (
      <div className="gpt-sec">
        <LoaderComponent isLoading={loading} />

        <div className="gpt-table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <input type="checkbox" disabled />
                </th>
                <th>Name</th>
                <th>Description</th>
                <th>Start date</th>
              </tr>
            </thead>
            <tbody>
              {gptLifeStyles?.map((item, index) => (
                <tr key={item.Type}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.some((i) => i.Type === item.Type)}
                      onChange={() => handleCheck(item)}
                    />
                  </td>
                  <td>{item.Type}</td>
                  <td>{item.Description}</td>
                  <td>
                    <input
                      className="display-element-Gnrl"
                      type="datetime-local"
                      value={item?.RecordedDate?.slice(0, 16)}
                      onChange={(event) => handleDateChange(event, item, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (gptData) {
      setLoading(false);
      setGptLifeStyles(
        gptData.map((e) => ({
          Type: e.name,
          Description: e.description,
          RecordedDate: `${new Date().toISOString()}`,
          EndDate: "",
          Status: true,
        }))
      );
    }
  }, [gptData]);
  return (
    <div>
      <DropDownContent title={"Lifestyle Recommendations"}>
        {" "}
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Lifestyle Recommendations"}
              iconsList={[
                {
                  value: "mingcute:ai-line",
                  onClickFn: () => {
                    lifestyleRecommendationsWindow === "gptCodesSection"
                      ? refreshGptCodes()
                      : setLifestyleRecommendationsWindow("gptCodesSection");
                  },
                },
                {
                  value: "ic:twotone-plus",
                  onClickFn: addLifestyleRecommendationsDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    lifestyleRecommendationsWindow === "addDetails"
                      ? () => onClickFn()
                      : lifestyleRecommendationsWindow === "gptCodesSection"
                      ? () => addGptCodes()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () =>
                    cancleOperationFn(setLifestyleRecommendationsWindow),
                },
              ]}
            >
              <div>
                {lifestyleRecommendationsWindow === "gptCodesSection"
                  ? renderGptSection()
                  : lifestyleRecommendationsWindow === "addDetails"
                  ? renderAddDetailsSection()
                  : renderCarePlanSection()}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.LifeStyle)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.LifeStyle)}
                    contentArray={[
                      ["Type:", "Type", "colSpan12"],
                      ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Description:", "Description", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default LifeStyleRecomentation;

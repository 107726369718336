import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const Vitals = (props) => {
  const { selectedPatient, id, updateDatafn } = props;

  const [vitalsWindow, setVitalsWindow] = useState("");
  const addVitalsDetail = () => {
    setVitalsWindow("addDetails");
    resetfn(vitals, setVitals);
  };
  const [vitalsArray, setVitalsArray] = useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [vitals, setVitals] = useState({
    Weight: "",
    Height: "",
    Pulse: "",
    O2Saturation: "",
    BloodPressure: "",
    PostprandialGlucose: "",
    LDLCholesterol: "",
    RespirationRate: "",
    BodyPain: "",
    HeadCircumference: "",
    Temperature: "",
    FastingPlasmaGlucose: "",
    Hemoglobin: "",
    VitalsTakenOn: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(vitals)) {
      generalSaveButton(
        vitalsWindow,
        setVitalsWindow,
        vitals,
        setVitalsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(vitals) &&
      !areArraysSame(selectedPatient?.Vitals, vitalsArray)
    ) {
      updateDatafn({ PatientId: id, Vitals: vitalsArray });
    }
  }, [vitalsArray]);
  useEffect(() => {
    if (selectedPatient) {
      setVitalsArray(selectedPatient.Vitals);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Vitals"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Vitals"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addVitalsDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      vitalsWindow === "addDetails" ? () => onClickFn() : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () => cancleOperationFn(setVitalsWindow),
                  },
                ]}
              >
                {" "}
                <div>
                  {vitalsWindow === "addDetails" ? (
                    <AddDetailGeneral data={selectedPatient}>
                      <>
                        <p className="displayTitleGnrl">Vitals Information</p>
                        <div className="display-sec-Gnrl display-sec-Gnrl-three">
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl ">
                              Weight (lb)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.Weight}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "Weight"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Height (in)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.Height}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "Height"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Pulse (bpm)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.Pulse}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "Pulse"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              O2 Saturation (%)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.O2Saturation}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "O2Saturation"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Blood Pressure (mm/Hg)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.BloodPressure}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "BloodPressure"
                                )
                              }
                            />
                          </div>

                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl ">
                              2 Hour Postprandial Glucose (mg/dL)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.PostprandialGlucose}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "PostprandialGlucose"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              LDL Cholesterol (mg/dL)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.LDLCholesterol}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "LDLCholesterol"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Respiration Rate (bpm)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.RespirationRate}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "RespirationRate"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Body Pain*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.BodyPain}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "BodyPain"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Head Circumference (cm)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.HeadCircumference}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "HeadCircumference"
                                )
                              }
                            />
                          </div>

                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl ">
                              Temperature (O(F))*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.Temperature}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "Temperature"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Fasting Plasma Glucose (mg/dL)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.FastingPlasmaGlucose}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "FastingPlasmaGlucose"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Hemoglobin A1c (%)*:
                            </label>
                            <input
                              type="number"
                              onKeyDown={(evt) =>
                                evt.key === "e" && evt.preventDefault()
                              }
                              className="display-element-Gnrl "
                              value={vitals.Hemoglobin}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "Hemoglobin"
                                )
                              }
                            />
                            <label className="display-element-Gnrl ">
                              Vitals Taken On*:
                            </label>
                            <input
                              type="datetime-local"
                              id="meeting-time"
                              name="meeting-time"
                              className="display-element-Gnrl "
                              value={vitals.VitalsTakenOn?.slice(0, 16)}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setVitals,
                                  "VitalsTakenOn"
                                )
                              }
                            />
                          </div>
                        </div>
                      </>{" "}
                    </AddDetailGeneral>
                  ) : (
                    <>
                      {statusfinderFn("active", selectedPatient.Vitals)
                        ?.length === 0 ? (
                        <CarePlanErrorMessage msg="Patient doesn’t have any Vitals yet!" />
                      ) : (
                        <CarePlanDisplayGeneral
                          updateDatafn={updateDatafn}
                          name={"Vitals"}
                          array={vitalsArray}
                          id={id}
                          careplanview={careplanview}
                          values={[setVitalsWindow, setVitals]}
                          data={statusfinderFn(
                            "active",
                            selectedPatient.Vitals
                          )}
                          setEditArray={setEditArray}
                          dataSet={selectedPatient?.Vitals ?? []}
                          contentArray={[
                            ["Weight(lb):", "Weight", "colSpan6"],
                            [
                              "Respiration Rate (bpm)::",
                              "RespirationRate",
                              "colSpan6",
                            ],
                            ["Height (in):", "Height", "colSpan6"],
                            ["Body Pain:", "BodyPain", "colSpan6"],

                            ["Pulse (bpm):", "Pulse", "colSpan6"],
                            [
                              "Head Circumference (cm):",
                              "HeadCircumference",
                              "colSpan6",
                            ],
                            ["O2 Saturation (%):", "O2Saturation", "colSpan6"],
                            ["Temperature (O(F)):", "Temperature", "colSpan6"],

                            [
                              "Blood Pressure (mm/Hg):",
                              "BloodPressure",
                              "colSpan6",
                            ],
                            [
                              "Fasting Plasma Glucose (mg/dL):",
                              "FastingPlasmaGlucose",
                              "colSpan6",
                            ],
                            [
                              "2 Hour Postprandial Glucose (mg/dL):",
                              "PostprandialGlucose",
                              "colSpan6",
                            ],

                            ["Hemoglobin A1c (%):", "Hemoglobin", "colSpan6"],
                            [
                              "LDL Cholesterol (mg/dL):",
                              "LDLCholesterol",
                              "colSpan6",
                            ],
                            ["Vitals Taken On:", "VitalsTakenOn", "colSpan6"],
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </ActiveHistoryDropDown2>
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2 type={"History"}>
                {" "}
                <>
                  {" "}
                  {statusfinderFn("history", selectedPatient?.Vitals)
                    ?.length === 0 ? (
                    <></>
                  ) : (
                    <CarePlanDisplayGeneral
                      Type={"history"}
                      data={statusfinderFn("history", selectedPatient.Vitals)}
                      contentArray={[
                        ["Weight(lb):", "Weight", "colSpan6"],
                        [
                          "Respiration Rate (bpm)::",
                          "RespirationRate",
                          "colSpan6",
                        ],
                        ["Height (in):", "Height", "colSpan6"],
                        ["Body Pain:", "BodyPain", "colSpan6"],

                        ["Pulse (bpm):", "Pulse", "colSpan6"],
                        [
                          "Head Circumference (cm):",
                          "HeadCircumference",
                          "colSpan6",
                        ],
                        ["O2 Saturation (%):", "O2Saturation", "colSpan6"],
                        ["Temperature (O(F)):", "Temperature", "colSpan6"],

                        [
                          "Blood Pressure (mm/Hg):",
                          "BloodPressure",
                          "colSpan6",
                        ],
                        [
                          "Fasting Plasma Glucose (mg/dL):",
                          "FastingPlasmaGlucose",
                          "colSpan6",
                        ],
                        [
                          "2 Hour Postprandial Glucose (mg/dL):",
                          "PostprandialGlucose",
                          "colSpan6",
                        ],

                        ["Hemoglobin A1c (%):", "Hemoglobin", "colSpan6"],
                        [
                          "LDL Cholesterol (mg/dL):",
                          "LDLCholesterol",
                          "colSpan6",
                        ],
                        ["Vitals Taken On:", "VitalsTakenOn", "colSpan6"],
                      ]}
                    />
                  )}
                </>
              </ActiveHistoryDropDown2>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Vitals;

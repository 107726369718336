import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useLoginMutation } from "../app/api/loginApi";
import { authDataActions } from "../app/features/authSlice";
import { loginDataActions } from "../app/features/loginDataSlice";

import classNames from "classnames";
import ButtonComponent from "../components/ButtonComponent";
import TwoFA_InputField from "../fieldComponent/TwoFA_InputField";
import FloatingNotifications from "../utilities/FloatingNotifications";
import "./twoFA.scss";

const TwoFA = () => {
  const loginData = useSelector((state) => state.loginData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [misMatchText, setMisMatchText] = useState("");

  const from = location.state?.from?.pathname ?? "/";
  const [validateLogin, { data, isLoading, error }] = useLoginMutation();

  const { twoFA } = loginData;
  const twoFA_AutoSubmit = useSelector(
    (state) => state.loginData.twoFA_AutoSubmit
  );
  const [inputData, setInputData] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  const resetRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
    6: useRef(null),
  };

  const storeData = useCallback((fieldName, value) => {
    setInputData((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
    if (fieldName !== 6 && value !== "") {
      resetRefs[fieldName + 1].current.focusData();
    }
  }, []);

  const submitHandler = () => {
    dispatch(loginDataActions.submitTwoFA());
    const { username, password } = loginData;
    // console.log({ username, password, twofa: twoFA });
    validateLogin({ username, password, twofa: twoFA });
  };
  const resentOtp = () => {
    const { username, password } = loginData;
    // console.log({ username, password, twofa: twoFA });
    validateLogin({ username, password });
  };

  const handlePaste = (event) => {
    const paste = event.clipboardData.getData("text").trim();
    if (paste.length === 6) {
      Object.keys(inputData).forEach((key, index) => {
        const digit = paste[index];
        resetRefs[parseInt(key)].current?.init_data?.(digit);
      });
    }
    event.preventDefault();
  };

  useEffect(() => {
    let inpKeys = Object.keys(inputData);
    inpKeys.sort();
    const result = inpKeys.reduce((prev, cur) => prev + inputData[cur], "");
    dispatch(loginDataActions.updateTwoFA(result));
  }, [inputData]);

  useEffect(() => {
    if (twoFA.length === 6) {
      if (twoFA_AutoSubmit) {
        submitHandler();
      }
    }
  }, [twoFA]);

  useEffect(() => {
    if (data?.code === 2000) {
      dispatch(authDataActions.auth(data));
      navigate(from, { replace: true });
    } else {
      if (data?.msg) {
        setMisMatchText(data.msg);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      // console.log(error);
      FloatingNotifications("error", error?.data?.msg ?? "Unknown error");
    }
  }, [error]);

  return (
    <>
      <div className="title">Two-Factor Authentication</div>
      <div className="desc">Check your registered email for OTP</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitHandler();
        }}
      >
        <div className="OTP_InputContainer">
          <TwoFA_InputField
            onPaste={handlePaste}
            ref={resetRefs[1]}
            storeData={storeData.bind(null, 1)}
            {...{ autoFocus: true }}
          />
          <TwoFA_InputField
            ref={resetRefs[2]}
            storeData={storeData.bind(null, 2)}
          />
          <TwoFA_InputField
            ref={resetRefs[3]}
            storeData={storeData.bind(null, 3)}
          />
          <TwoFA_InputField
            ref={resetRefs[4]}
            storeData={storeData.bind(null, 4)}
          />
          <TwoFA_InputField
            ref={resetRefs[5]}
            storeData={storeData.bind(null, 5)}
          />
          <TwoFA_InputField
            ref={resetRefs[6]}
            storeData={storeData.bind(null, 6)}
          />
        </div>
        <ButtonComponent
          containerClassName="buttonContainer"
          onClick={submitHandler}
          text="Verify"
          disabled={isLoading}
        />
        <ButtonComponent
          containerClassName="buttonContainer"
          onClick={resentOtp}
          text="Resend Otp"
          disabled={isLoading}
        />
      </form>
      <div className="issueNavigation">
        {/* <span>Reset two-factor authentication</span> */}
      </div>
      <div
        className={classNames("errorLogin", { hidden: misMatchText === "" })}
      >
        {misMatchText === "" ? "hidden" : misMatchText}
      </div>
    </>
  );
};
export default TwoFA;

import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useAddServicesMutation } from "../app/api/billingApi";
// import { useUpdateCarePlanMutation } from '../app/api/patientsApi';
import { useTimerApiMutation } from "../app/api/patientsApi";
import { useTimerfinalapiMutation } from "../app/api/timerapifinal";
import { useNavigate } from "react-router-dom";
import PopUp from "../patients/patientViewPage/PopUp";
import { useSelector, useDispatch } from "react-redux";
import { routingStateActions } from "../app/features/RoutingStateSlice";
import "../sass/stopWatch.scss";

const Stopwatch = (props) => {
  const {
    id,
    updateFunction,
    serviceName,
    startTimer,
    programName,
    setStartTimer,
    callingpage,
    handlestopWatch,
    timerFn,
  } = props;
  // const [updateDatafn, { data: updatedData }] = useUpdateCarePlanMutation();
  const [isRunning, setIsRunning] = useState(false);
  // const [buttonClick, setButtonClick] = useState(1);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState();
  const [showModal, setShowModal] = useState(false);
  const [TimerFn, { data: TimerData }] = useTimerfinalapiMutation();
  const [addServicesFn, { data: serviceData }] = useAddServicesMutation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRunning) {
        event.preventDefault();
        event.returnValue = "Stop the Timer before leaving the page!";
      }
    };

    const handlePopState = (event) => {
      if (isRunning) {
        navigate(window.location.pathname);
        alert("Stop the Timer before leaving the page!");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isRunning, navigate]);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      // setButtonClick((prev) => prev + 1);
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning]);

  const handleStart = () => {
    if (handlestopWatch) {
      handlestopWatch(true);
    }
    setIsRunning(true);

    setStartTime(new Date());
    dispatch(routingStateActions.disableRouting());
  };

  const handleStop = () => {
    if (handlestopWatch) {
      handlestopWatch(false);
    }
    setIsRunning(false);
    dispatch(routingStateActions.enableRouting());
  };

  const handleReset = async () => {
    if (handlestopWatch) {
      handlestopWatch(false, true);
    }
    let endTime = new Date();
    if (updateFunction) {
      const total = parseTime(props?.totalTime);

      await updateFunction({
        PatientId: id,
        TotalTime: formatTime(elapsedTime + total),
      });
    }
    dispatch(routingStateActions.enableRouting());

    setStartTime("");
    setElapsedTime(0);
    setIsRunning(false);
    if (callingpage) {
      await timerFn({
        startTime: startTime,
        endTime: endTime,
        acutalTimeSpent: formatTime(elapsedTime),
      });
    } else {
      addServicesFn({
        PatientId: id,
        serviceName: serviceName ?? "CCM",
        programName: programName ?? "CCM",
        serviceType: "Timer",
        startTime: startTime,
        endTime: endTime,
        acutalTimeSpent: formatTime(elapsedTime),
        remark: "notes",
      });
    }
  };
  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString?.split(":")?.map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const cancelfn = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (startTimer) {
      handleStart();
      setStartTimer(false);
    }
  }, [setStartTimer, startTimer]);

  return (
    <>
      {/* {showModal && (
        <PopUp cancelfn={cancelfn}>
          <div className="signature-section">
            <p className="signature">Alert! the Timer is still running.</p>
          </div>
        </PopUp>
      )} */}
      <div className="stopwatchContainer">
        <div className="timerContainer">
          <div className="title">Timer</div>
          <div className="timer">{formatTime(elapsedTime)}</div>
        </div>
        <div className="timerbuttons">
          <button onClick={handleStart}>
            <Icon color="#fff" icon="ph:play-fill" width="25" height="25" />
          </button>
          <button onClick={handleStop}>
            <Icon
              color="#fff"
              icon="material-symbols:pause"
              width="25"
              height="25"
            />
          </button>
          <button onClick={handleReset}>
            <Icon color="#fff" icon="ic:sharp-stop" width="25" height="25" />
          </button>
        </div>
        {/* <div className="dialogBox">
        <CarePlanDialogBox buttonClick={buttonClick} startTimer={handleStart} />
      </div> */}
      </div>
    </>
  );
};

export default Stopwatch;

import {
  StyleSheet,
  PDFViewer,
  Document,
  Page,
  Image,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";
import { DateInput } from "rsuite";

const fontURL =
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";

// Font.register({ family: "Roboto" });
Font.register({
  family: "Roboto1",
  // src: fontURL,
  fontStyle: "normal",
  // fontWeight: "normal",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2",
      fontWeight: "100",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2",
      fontWeight: "300",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2",
      fontWeight: "400",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2",
      fontWeight: "500",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2",
      fontWeight: "700",
    },
    {
      src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2",
      fontStyle: "normal",
      fontWeight: "heavy",
    },
  ],
});

const color = "#E4E4E4";
// Create styles
const styles = StyleSheet.create({
  page: {
    padding: "2cm 2cm",
    // fontFamily: "Roboto",
    // display: "flex",
  },
  title: {
    position: "absolute",
    fontSize: 12,
    top: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    // color,

    // width: "100%",
    // textAlign: "center",
    // fontWeight: "extrabold",
    // fontSize: "0.5cm",
    // backgroundColor: color,
    // marginBottom: "0.8cm",
  },
  subTitle: {
    width: "100%",
    textAlign: "center",
    backgroundColor: color,
    marginTop: "0.5cm",
    marginBottom: "0.3cm",
    padding: "0.2cm",
    fontSize: "0.48cm",
    fontWeight: "900",
  },
  patientDetails: {
    display: "flex",
    flexDirection: "row",
    fontSize: "0.3cm",

    border: "3pt solid #ddd",
    padding: "0.3cm",
    marginBottom: "0.3cm",
    flexWrap: "wrap",
  },
  patientDetailsLeft: {
    flex: 1,
  },
  patientRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 4,
  },
  borderContainer: {
    // border: "1pt solid #ddd",
    padding: "0.3cm",
    backgroundColor: "#fff",
  },
  subHeadingBox: {
    fontSize: "0.3cm",
    display: "flex",
    flexDirection: "row",
  },
  subHeadingText: {
    backgroundColor: color,
    padding: "0.2cm",
  },
  patientTitle: {
    fontSize: "0.3cm",
    fontFamily: "Roboto1",
    fontStyle: "normal",
    fontWeight: "heavy",
    marginRight: 7,
  },
  patientValue: {
    flexShrink: 1,
  },
  patientContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: 120,
    height: 50,
    marginBottom: 2,
  },
  underline: { width: 150, height: 1, backgroundColor: "black", marginTop: 1 },

  test: {
    backgroundColor: "red",
  },
  bulletinContainer: {
    display: "flex",
    flexDirection: "row",
  },
  bulletinPoint: {
    width: "0.15cm",
    height: "0.15cm",
    backgroundColor: color,
    margin: "1.7mm",
    marginRight: "0.2cm",
  },
  normalData: {
    fontSize: "0.3cm",
    fontFamily: "Roboto1",
    fontStyle: "normal",
    fontWeight: "heavy",
  },
  space: {
    padding: "2mm",
  },
  termsAndCond: {
    fontSize: 10,
  },
  points: {
    fontSize: 10,
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  signatureChild: {
    width: "50%",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    // color: "grey",
  },
  dateFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    right: 0,
    marginRight: "2cm",
  },
  patientFooter: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    marginLeft: "2cm",
  },
});

const convertDateTostring = (date) => {
  // console.log(date, typeof date, date.replace("T", " ").replace(".000Z", ""));

  const res = date.replace("T", " ").replace(".000Z", "");

  return res;
};
const formatAMPM = (date) => {
  if (date === undefined) return "";
  if (typeof date === "string") {
    date = new Date(date);
  }
  var dd = date.getDate(date);
  var mm = date.getMonth(date) + 1;

  const yy = date.getFullYear(date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  dd = dd < 10 ? "0" + dd : dd;
  mm = mm < 10 ? "0" + mm : mm;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = `${mm}/${dd}/${yy} ${hours}:${minutes} ${ampm}`;
  return strTime;
};

const Bulletin = ({ children }) => {
  return (
    <View style={styles.bulletinContainer}>
      <View style={styles.bulletinPoint}></View>
      <Text style={styles.normalData}>{children}</Text>
    </View>
  );
};

const ContentBox = ({ heading, normalData, BulletinData, footer }) => {
  return (
    <View wrap={false}>
      <View style={styles.subHeadingBox}>
        <Text style={[styles.subHeadingText]}>{heading}</Text>
      </View>
      <View style={styles.borderContainer}>
        {normalData?.length &&
          normalData.map((el) => <Text style={styles.normalData}>{el}</Text>)}
        {BulletinData?.length &&
          BulletinData.map((el) => (
            <Bulletin>
              <>{el}</>
            </Bulletin>
          ))}
        {footer && (
          <>
            <Space />
            <Text style={styles.points}>{footer}</Text>
            <Space />
          </>
        )}
        {/* <Bulletin></Bulletin>
        <Bulletin />
        <Bulletin /> */}
      </View>
    </View>
  );
};
const Space = () => {
  return <View style={styles.space}></View>;
};
const doc = ({ data, image, provider, date }) => {
  // <PDFViewer>
  const padEnd = 20;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber}`}
          fixed
        />
        <Text
          style={[styles.title]}
          render={() =>
            `Care Plan For ${data.Name} as of ${formatAMPM(
              new Date(data.updatedAt ?? new Date())
            )}`
          }
          fixed
        />
        <Text
          style={[styles.dateFooter]}
          render={() => `Print date : ${formatAMPM(new Date())}`}
          fixed
        />
        <Text
          style={[styles.patientFooter]}
          render={() => `${data.Name}`}
          fixed
        />
        <View style={styles.patientDetails}>
          <View style={styles.patientDetailsLeft}>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Patient: </Text>
              {data?.Name ?? ""}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Date of Birth: </Text>
              {data?.["Date Of Birth"]}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Email: </Text>
              {data?.["email"]}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Care Status: </Text>
              {data?.["careStatus"] ?? "Active"}
            </Text>
          </View>
          <View style={styles.patientDetailsLeft}>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Gender: </Text>
              {data?.Gender ?? ""}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Phone: </Text>
              {data?.["Phone Number"] ?? " -"}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Address: </Text>
              {data?.["Address"]}
            </Text>
            <Text style={styles.patientRow}>
              <Text style={styles.patientTitle}>Facility: </Text>
              {data?.FacilityId?.FacilityName ?? " -"}
            </Text>
          </View>
        </View>
        <View style={[styles.subTitle]}>
          <Text>CARE TEAM</Text>
        </View>
        <View
          style={[
            styles.borderContainer,
            { flexDirection: "row", justifyContent: "space-between" },
          ]}
        >
          {/* <ContentBox
            heading="Care Plan Physician"
            normalData={["sfvwsef"]}
            BulletinData={[
              "sfgswrgv",
              "aegwgwef",
              <>
                asdgvsrsw <Text>sdvsg</Text>
              </>,
            ]}
          /> */}
          <ContentBox
            heading="Care Plan Physician"
            BulletinData={[data?.ProviderId?.ProviderName ?? " -"]}
          />
          <Space />
          <ContentBox
            heading="Primary Care Coordinator"
            BulletinData={[data?.PrimaryCoordinator?.ClinicianName ?? " -"]}
          />
          <Space />
          <ContentBox
            heading="Primary CCM Clinician"
            // normalData={["Primary CCM Clinician"]}
            BulletinData={[data?.primaryCCMClinician?.ClinicianName ?? " -"]}
          />
        </View>
        <View style={[styles.subTitle]}>
          <Text>CCM CARE PLAN</Text>
        </View>
        <View>
          {data?.ComprehensiveAssessment && (
            <>
              <ContentBox
                heading="Comprehensive Assessment"
                BulletinData={[
                  <Text style={{ fontWeight: "heavy" }}>
                    {data.ComprehensiveAssessment}
                  </Text>,
                ]}
              />
              <Space />
            </>
          )}

          {data?.ReviewofSystem && (
            <>
              <ContentBox
                heading="Review of System"
                BulletinData={Object.entries(data?.ReviewofSystem[0])?.map(
                  ([key, value]) => (
                    <>
                      {key}
                      <Text
                        style={{ fontWeight: "heavy" }}
                      >{` - (${value})`}</Text>
                    </>
                  )
                )}
                // footer={
                //   "	The information above was collected and obtained from the EMR system."
                // }
              />

              <Space />
            </>
          )}
          {data?.Mobility && (
            <>
              <ContentBox
                heading="Mobility"
                BulletinData={[data?.Mobility ?? " -"]}
              />
              <Space />
            </>
          )}
          {data?.Diagnosis?.filter((el) => el.Status === true).length > 0 && (
            <>
              <ContentBox
                heading="Diagnoses"
                BulletinData={data?.Diagnosis?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    {el.ICD_Description}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{` - (${el.Type})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}
          {data?.GoalInformation?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading="Diagnoses based Goals"
                BulletinData={data?.GoalInformation?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Goal}) `}</Text>
                    - {el.Description}
                  </>
                ))}
              />
              <Space />
            </>
          )}
          {data?.LifeStyle?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Lifestyle Recommendations"
                BulletinData={data?.LifeStyle?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Type})`}</Text>
                    {` : ${el.Description}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.["Emergency and Urgent Needs Plan"] && (
            <>
              <ContentBox
                heading="Emergency and Urgent Needs Plan"
                BulletinData={[
                  <Text style={{ fontWeight: "heavy" }}>
                    {data["Emergency and Urgent Needs Plan"]}
                  </Text>,
                ]}
              />
              <Space />
            </>
          )}
          {data?.Symptoms?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Symptoms"
                BulletinData={data?.Symptoms?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`${el.Symptoms}`}</Text>
                    {`(For ${el.DurationValue} ${el.Duration})`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Prognosis?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Expected Outcomes and Prognosis"
                BulletinData={data?.Prognosis?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>{el.Notes}</>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Concerns?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Concerns"
                BulletinData={data?.Concerns?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>{el.Notes}</>
                ))}
              />
              <Space />
            </>
          )}

          {data?.FamilyHistory?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading="Family History"
                BulletinData={data?.FamilyHistory?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    {`${el.Code} (${el.Condition}) - `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.ConditionExistsForRelationship.join(",")})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.OtherRecommendations?.filter((el) => el.Status === true)
            ?.length > 0 && (
            <>
              <ContentBox
                heading="Other Recommendations"
                BulletinData={data?.OtherRecommendations?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Type})`}</Text>
                    {` : ${el.Recommendations}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Barriers?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Barriers"
                BulletinData={data?.Barriers?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>{`${el.Recommendations}`}</>
                ))}
              />
              <Space />
            </>
          )}

          {data?.PreventiveCareAndSchedule?.filter((el) => el.Status === true)
            ?.length > 0 && (
            <>
              <ContentBox
                heading="Preventive Care and Schedule"
                BulletinData={data?.PreventiveCareAndSchedule?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>{`${formatAMPM(el.ScheduledDate)} : ${
                    el.Recommendations
                  }`}</>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Medications?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading="Medications"
                BulletinData={data?.Medications?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    {el.Medication} {el.Quantity} - {el.Frequency} - From{" "}
                    {convertDateTostring(el.RecordedDate)} | notes:{el.Notes}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Allergies?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading="Allergies"
                BulletinData={data?.Allergies?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    {`${el.Description} `}
                    {`(Type : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AllergyType})`}</Text>
                    {`, Subtype : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AllergySubType})`}</Text>
                    {`, Reaction : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Reaction})`}</Text>
                    {`, Severity : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Severity})`}</Text>
                    {`) from ${formatAMPM(el.ScheduledDate)}`}
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Immunization?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading="Immunization"
                BulletinData={data?.Immunization?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>
                    {`Vaccination Name : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.VaccinationName})`}</Text>
                    {` ${el.Dose} administered by `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.AdministeredPhysician})`}</Text>
                    {` on `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.VaccinationAdministeredOn})`}</Text>
                    {`, Route : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.RoutesOfAdministration})`}</Text>
                    {`, Site : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.Site})`}</Text>
                    {`, Manufacturer : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.NameOfManufacturer})`}</Text>
                    {`, Lot Number : `}
                    <Text
                      style={{ fontWeight: "heavy" }}
                    >{`(${el.LotNumber})`}</Text>
                    {`, Expiration Date & Time : `}
                    <Text style={{ fontWeight: "heavy" }}>{`(${formatAMPM(
                      el.ExpirationDate
                    )})`}</Text>
                    {`, Vaccination Reason : `}
                    <Text style={{ fontWeight: "heavy" }}>{`(${formatAMPM(
                      el.VaccinationReason
                    )})`}</Text>
                  </>
                ))}
              />
              <Space />
            </>
          )}

          {data?.MedicalEvents?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading="Significant Medical Events"
                BulletinData={data?.MedicalEvents?.filter(
                  (el) => el.Status === true
                )?.map((el) => (
                  <>{`${formatAMPM(el.EventDate)} ${el.eventType} - ${
                    el.Description
                  } - ${el.Notes}`}</>
                ))}
              />
              <Space />
            </>
          )}

          {data?.Vitals?.filter((el) => el.Status === true)?.length > 0 && (
            <>
              <ContentBox
                heading={`Vitals (${formatAMPM(data?.Vitals[0]?.createdAt)})`}
                BulletinData={[
                  `Height (in)                            : ${data?.Vitals[0]?.Height}`,
                  `Weight (lb)                            : ${data?.Vitals[0]?.Weight}`,
                  // `BMI                                 : ${data?.Vitals[0]?.}`,
                  `Blood Pressure (mm/Hg)                 : ${data?.Vitals[0]?.BloodPressure}`,
                  `Temperature (°F)                       : ${data?.Vitals[0]?.Temperature}`,
                  `Pulse (bpm)                            : ${data?.Vitals[0]?.Pulse}`,
                  `Respiration Rate (bpm)                 : ${data?.Vitals[0]?.RespirationRate}`,
                  `O2 Saturation (bpm)                    : ${data?.Vitals[0]?.O2Saturation}`,
                  `Body Pain (bpm)                        : ${data?.Vitals[0]?.BodyPain}`,
                  `Head Circumference (cm)                : ${data?.Vitals[0]?.HeadCircumference}`,
                  `Fasting Plasma Glucose (mg/dL)         : ${data?.Vitals[0]?.FastingPlasmaGlucose}`,
                  `2-hour Postprandial Glucose (mg/dL)    : ${data?.Vitals[0]?.PostprandialGlucose}`,
                  `LDL Cholesterol (mg/dL)                : ${data?.Vitals[0]?.LDLCholesterol}`,
                  // `HDL Cholesterol (mg/dL)             : ${data?.Vitals[0]?.HDLCholesterol}`,
                  // `Triglycerides (mg/dL)               : ${data?.Vitals[0]?.Triglycerides}`,
                  // `Total Cholesterol (mg/dL)           : ${data?.Vitals[0]?.TotalCholesterol}`,
                  `2-hour Postprandial Glucose (mg/dL)    : ${data?.Vitals[0]?.PostprandialGlucose}`,
                  `Hemoglobin A1C (%)                     : ${data?.Vitals[0]?.HemoglobinA1C}`,
                ]}
              />
              <Space />
            </>
          )}

          {data?.LifestyleRemarks?.filter((el) => el.Status === true)?.length >
            0 && (
            <>
              <ContentBox
                heading={`Lifestyle (${formatAMPM(
                  data?.LifestyleRemarks[0]?.RecordedDate
                )})`}
                BulletinData={[
                  `Alcohol               :  ${data?.LifestyleRemarks[0]?.Alcohol}(${data?.LifestyleRemarks[0]?.AlcoholRemarks})`,
                  `Caffeine              :  ${data?.LifestyleRemarks[0]?.Caffeine}(${data?.LifestyleRemarks[0]?.CaffeineRemarks})`,
                  `Physical Activity     :  ${data?.LifestyleRemarks[0]?.PhysicalActivity}(${data?.LifestyleRemarks[0]?.PhysicalActivityRemarks})`,
                  `Recreational Drugs    :  ${data?.LifestyleRemarks[0]?.RecreationalDrugs}(${data?.LifestyleRemarks[0]?.RecreationalDrugsRemarks})`,
                  `Smoking               :  ${data?.LifestyleRemarks[0]?.Smoking}(${data?.LifestyleRemarks[0]?.SmokingRemarks})`,
                ]}
              />
              <Space />
            </>
          )}
        </View>

        <View style={styles.termsAndCond}>
          <View>
            <Text>
              Information obtained from the EMR and patient interactions.
              Diagnostic results reviewed and evaluated prior to the patient
              encounter. Pre-visit assessment included review of encounter
              notes, HPI, and current medications. The Care Team will update the
              care plan based on EMR documentation and patient interactions
              every 90 days, or earlier as clinically indicated, including but
              not limited to medication interactions or changes in the patient’s
              condition per established guidelines.
            </Text>

            <Space />
            <Space />
            {/* <Text>
              Patient Consent: The patient has been provided informed verbal
              consent for the CCM program at the time of annual enrollment.
            </Text> */}
          </View>
        </View>
        {/* <Space />
        <Space />
        <Space />
        <Space /> */}
        {image && (
          <View style={styles.imageContainer}>
            <Image src={image} style={styles.image} />
            <View style={styles.underline} />
          </View>
        )}
        {provider && (
          <View style={styles.signatureContainer}>
            <Text style={styles.signatureChild}>{provider}</Text>
            <Text style={styles.signatureChild}>Date: {date} </Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default doc;

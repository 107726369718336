import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearchGeneral from "../../searchandselect component/SearchAndSelectGeneral";
import classNames from "classnames";
import MultipleSelectChip from "../../../components/MultipleSelectChip.jsx";
import "react-widgets/styles.css";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import Multiselect from "react-widgets/Multiselect";
import FloatingNotifications from "../../../utilities/FloatingNotifications";

import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
import { useGetSNOMEDMutation } from "../../../app/api/patientsApi";
const FamilyHistory = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [familyHistoryWindow, setFamilyHistoryWindow] = useState("");
  const [active, setActive] = useState("Active");

  const addFamilyHistoryDetail = () => {
    setFamilyHistoryWindow("addDetails");
    resetfn(familyHistory, setFamilyHistory);
  };
  const [familyHistoryArray, setFamilyHistoryArray] = useState([]);
  const [editArray, setEditArray] = useState();

  const [familyHistory, setFamilyHistory] = useState({
    Code: "",
    ConditionExistsForRelationship: [],
    Condition: "",
    Comments: "",
    Status: true,
  });
  const [getSnoMedct, { data: SnoMedData }] = useGetSNOMEDMutation();

  const returnDisplayFn = (dataItem) => {
    return dataItem["ConditionExistsForRelationship"].toString();
  };
  const onClickFn = () => {
    const { Comments, ...familyWithoutComments } = familyHistory;

    if (basicValidation(familyWithoutComments)) {
      generalSaveButton(
        familyHistoryWindow,
        setFamilyHistoryWindow,
        familyHistory,
        setFamilyHistoryArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (selectedPatient) {
      setFamilyHistoryArray(selectedPatient.FamilyHistory);
    }
  }, [selectedPatient]);

  useEffect(() => {
    const { Comments, ...familyWithoutComments } = familyHistory;

    if (
      basicValidation(familyWithoutComments) &&
      !areArraysSame(selectedPatient?.FamilyHistory, familyHistoryArray)
    ) {
      updateDatafn({ PatientId: id, FamilyHistory: familyHistoryArray });
    }
  }, [familyHistoryArray]);

  const setConditionForRelationFn = (val) => {
    setFamilyHistory((prev) => ({
      ...prev,
      ConditionExistsForRelationship: val,
    }));
  };

  return (
    <div>
      <DropDownContent
        title={"Family History"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Family History"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addFamilyHistoryDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      familyHistoryWindow === "addDetails"
                        ? () => onClickFn()
                        : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () => cancleOperationFn(setFamilyHistoryWindow),
                  },
                ]}
              >
                <div>
                  {familyHistoryWindow === "addDetails" ? (
                    <AddDetailGeneral data={selectedPatient}>
                      <>
                        <p className="displayTitleGnrl">
                          Family History Details
                        </p>
                        <div className="display-sec-Gnrl">
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Code*
                            </label>
                            <select
                              className="display-element-Gnrl"
                              value={familyHistory.Code}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setFamilyHistory,
                                  "Code"
                                )
                              }
                            >
                              <option className="default" value=""></option>
                              <option value="SNOMED Code">SNOMED Code </option>
                              <option value="Other">Other</option>
                            </select>
                            {familyHistory.Code === "SNOMED Code" && (
                              <>
                                <label className="display-element-Gnrl">
                                  Search by code*
                                </label>
                                <SelectWithSearchGeneral
                                  options={SnoMedData}
                                  Apicallfn={getSnoMedct}
                                  placeHolder={
                                    "Search BY SNOMED Code/Condition"
                                  }
                                  type={"familyHistory"}
                                  setvalue={setFamilyHistory}
                                  name={"Condition"}
                                  fn={generalHandleValueChanges}
                                  valuedata={familyHistory.Condition}
                                  // decription={"Condition"}
                                  // ApiOption={true}
                                  // selectedPatient={selectedPatient}
                                />
                              </>
                            )}

                            {/* <select className="display-element-Gnrl">
                            <option className="default" value=""></option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu </option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu</option>
                            <option value="KLDSNJLKEMNDLAD">lewo</option>
                          </select> */}

                            <label className="display-element-Gnrl">
                              Condition*
                            </label>
                            <div
                              className={classNames(
                                familyHistory.Code === "SNOMED Code"
                                  ? "displayDescription-color"
                                  : "display-element-Gnrl"
                              )}
                            >
                              <textarea
                                disabled={
                                  familyHistory.Code === "SNOMED Code"
                                    ? true
                                    : false
                                }
                                className="display-element-Gnrl"
                                value={familyHistory.Condition}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setFamilyHistory,
                                    "Condition"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="display-content-Gnrl">
                            <label className="display-element-Gnrl">
                              Condition exists for relationship*
                            </label>
                            <div className="multiselectCont">
                              <Multiselect
                                // dropUp
                                value={
                                  familyHistory.ConditionExistsForRelationship
                                  // familyHistory.ConditionExistsForRelationship
                                  //   .length === 0
                                  //   ? []
                                }
                                // value={["Brother"]}
                                onChange={(val) =>
                                  setConditionForRelationFn(val)
                                }
                                data={[
                                  "Brother",
                                  "Daughter",
                                  "Father",
                                  "Mother",
                                  "Sister",
                                  "Son",
                                  "Spouse",
                                  "Grand Mother",
                                  "Grand Father",
                                  "Aunty",
                                  "Uncle",
                                  "unknown",
                                ]}
                              />
                            </div>

                            {/* <select
                            className="display-element-Gnrl"
                            value={familyHistory.ConditionExistsForRelationship}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setFamilyHistory,
                                "ConditionExistsForRelationship"
                              )
                            }
                          >
                            <option className="default" value=""></option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu </option>
                            <option value="KLDSNJLKEMNDLAD">lewohdoiu</option>
                            <option value="KLDSNJLKEMNDLAD">lewo</option>
                          </select> */}
                            <label className="display-element-Gnrl">
                              Comments
                            </label>
                            <div className="displayDescription">
                              <textarea
                                className="display-element-Gnrl"
                                value={familyHistory.Comments}
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setFamilyHistory,
                                    "Comments"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>{" "}
                    </AddDetailGeneral>
                  ) : (
                    <>
                      {statusfinderFn("active", selectedPatient.FamilyHistory)
                        ?.length === 0 ? (
                        <CarePlanErrorMessage msg="Patient doesn’t have any family History yet!" />
                      ) : (
                        <CarePlanDisplayGeneral
                          updateDatafn={updateDatafn}
                          name={"FamilyHistory"}
                          array={familyHistoryArray}
                          id={id}
                          careplanview={careplanview}
                          values={[setFamilyHistoryWindow, setFamilyHistory]}
                          data={statusfinderFn(
                            "active",
                            selectedPatient.FamilyHistory
                          )}
                          dataSet={selectedPatient?.FamilyHistory ?? []}
                          setEditArray={setEditArray}
                          contentArray={[
                            ["Code:", "Code", "colSpan12"],
                            [
                              "Condition exists for relationship:",
                              returnDisplayFn,
                              "colSpan12",
                              true,
                            ],
                            ["Condition:", "Condition", "colSpan6"],
                            ["Comments:", "Comments", "colSpan6"],
                          ]}
                        />
                      )}
                    </>
                  )}
                </div>
              </ActiveHistoryDropDown2>
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2 type={"History"}>
                {" "}
                <>
                  {" "}
                  {statusfinderFn("history", selectedPatient.FamilyHistory)
                    ?.length === 0 ? (
                    <></>
                  ) : (
                    <CarePlanDisplayGeneral
                      Type={"history"}
                      data={statusfinderFn(
                        "history",
                        selectedPatient.FamilyHistory
                      )}
                      contentArray={[
                        ["Code:", "Code", "colSpan12"],
                        [
                          "Condition exists for relationship:",
                          "ConditionExistsForRelationship",
                          "colSpan12",
                        ],
                        ["Condition:", "Condition", "colSpan6"],
                        ["Comments:", "Comments", "colSpan6"],
                      ]}
                    />
                  )}
                </>
              </ActiveHistoryDropDown2>
            )}
          </div>
        }
      />
    </div>
  );
};

export default FamilyHistory;

import React, { useState, useEffect } from "react";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import { generalHandleValueChanges } from "../helperfunctions";

const ReviewofSystem = ({ updateDatafn, selectedPatient, id }) => {
  const [data, setData] = useState({
    Constitutional: "Denies fever, chills, weakness, fatigue",
    Neck: "Lumps: No Swollen Glands:  No",
    Cardiovascular: "Denies chest pain or palpitations",
    Respiratory: "Denies shortness of breath",
    Gastrointestinal:
      "Denies abdominal pain, nausea, vomiting, diarrhea, or constipation",
    Genitourinary:
      "Denies increased frequency, burning and odorous urine, also denies hesitancy, and symptoms of infection.",
    Musculoskeletal: "Denies myalgias or joint pain",
    "Integumentary(Skin and/or Breast)": "Denies new rashes or lesions",
    Neurological: "Denies dizziness, tingling, or numbness",
    Psychiatric: "Cultural, Religious, or Language Issues: No",
  });
  const onClickFn = () => {
    updateDatafn({ PatientId: id, ReviewofSystem: data });
  };
  useEffect(() => {
    if (selectedPatient) {
      setData(selectedPatient?.["ReviewofSystem"]?.[0]);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Review of System"}
        children={
          <AddDetailGeneral data={selectedPatient}>
            <>
              <p className="displayTitleGnrl">Review of System</p>
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Review of System"}
                iconsList={[
                  {
                    value: "mingcute:save-line",
                    onClickFn: () => onClickFn(),
                  },
                ]}
              />
              <div className="display-sec-Gnrl">
                <div className="display-content-Gnrl ">
                  <label className="display-element-Gnrl">
                    Constitutional*
                  </label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Constitutional}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Constitutional"
                        )
                      }
                    />
                  </div>
                  <label className="display-element-Gnrl">
                    Gastrointestinal*
                  </label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Gastrointestinal}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Gastrointestinal"
                        )
                      }
                    />
                  </div>

                  <label className="display-element-Gnrl">Genitourinary*</label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Genitourinary}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Genitourinary"
                        )
                      }
                    />
                  </div>

                  <label className="display-element-Gnrl">
                    Musculoskeletal*
                  </label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Musculoskeletal}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Musculoskeletal"
                        )
                      }
                    />
                  </div>

                  <label className="display-element-Gnrl">
                    Integumentary(Skin and/or Breast)*
                  </label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.["Integumentary(Skin and/or Breast)"]}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Integumentary(Skin and/or Breast)"
                        )
                      }
                    />
                  </div>
                </div>
                <div className="display-content-Gnrl ">
                  <label className="display-element-Gnrl">Neck*</label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Neck}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setData, "Neck")
                      }
                    />
                  </div>
                  <label className="display-element-Gnrl">
                    Cardiovascular*
                  </label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Cardiovascular}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Cardiovascular"
                        )
                      }
                    />
                  </div>
                  <label className="display-element-Gnrl">Respiratory*</label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Respiratory}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setData, "Respiratory")
                      }
                    />
                  </div>
                  <label className="display-element-Gnrl">Psychiatric*</label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Psychiatric}
                      onChange={(event) =>
                        generalHandleValueChanges(event, setData, "Psychiatric")
                      }
                    />
                  </div>
                  <label className="display-element-Gnrl">Neurological*</label>
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data?.Neurological}
                      onChange={(event) =>
                        generalHandleValueChanges(
                          event,
                          setData,
                          "Neurological"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>{" "}
          </AddDetailGeneral>
        }
      ></DropDownContent>
    </div>
  );
};

export default ReviewofSystem;

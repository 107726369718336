import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useGetClinicianAllProfilesQuery } from "../../app/api/clinicianApi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../components/LoaderComponent";
import { generalHandleValueChanges } from "../../patients/Careteam/helperfunctions";
import SelectComponent from "../../components/SelectComponent";
import IconButton from "@mui/material/IconButton";

import "./clinicianPreview.scss";

// iconify
import { Icon } from "@iconify/react";

// import { makeStyles } from '@mui/material';
const useStyles = {
  TableContainer: {
    border: "0.5px solid #6D6D71",
    boxShadow: "none",
    maxHeight: "500px",
    overflowY: "auto",
  },
  tableHead: {
    backgroundColor: "#EEEEEE",
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "4px 4px 0px 0px",
  },
  tableHeaderTitle: {
    fontSize: "1.4rem",
    color: "#222222",
    borderRight: "0.5px solid #6D6D71",
    borderBottom: "2px solid #6D6D71",
  },
  tableBodyContent: {
    fontSize: "1.4rem",
    borderRight: "0.5px solid #6D6D71",
    padding: 0,
    paddingLeft: "1rem",
  },
  formControl: {
    "& .MuiInputBase-input": {
      fontSize: "  1.4rem",
    },
  },

  labelSelect: {
    fontSize: "  1.4rem",
  },

  select: {
    "&.Mui-focused": {
      color: "", // to overwrite the default behaviour
    },
    background: "white",
    color: "#222222",
    borderColor: " #6D6D71",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "10px",
    // paddingTop: '14px',
    // paddingBottom: '15px',
    "&:hover": {},
    "&:focus": {
      borderRadius: "4px",
      background: "white",
    },
  },
};

function ClinicianPreviewPage(props) {
  const navigate = useNavigate();
  const onClickEditBtn = (value) => {
    navigate("/clinician-preview/clinician", {
      state: { id: value, type: "Edit" },
    });
  };
  const onClickViewBtn = (value) => {
    navigate("/clinician-preview/clinician", {
      state: { id: value, type: "View" },
    });
  };

  const { data: dataFromApi, isLoading } = useGetClinicianAllProfilesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [data, setData] = useState([]);
  const [dataForFilter, setDataForFilter] = useState([]);
  useEffect(() => {
    if (!dataFromApi) return;
    setDataForFilter(dataFromApi);
  }, [dataFromApi]);
  // const classes = useStyles();
  useEffect(() => {
    if (!dataFromApi) return;
    setData(dataFromApi);
    // console.log(rawAccessLevelData);
  }, [dataFromApi]);

  // const [navItems, setNavItems] = useState({
  //   showItems: 10,
  //   status: "active",
  // });
  const handleSearch = (e) => {
    let target = e.target;
    if (target.value === "") {
      setData(dataForFilter);
    } else {
      const filtertdata = dataForFilter.filter((x) =>
        x.Name.toLowerCase().includes(target.value.toLowerCase())
      );
      setData(filtertdata);
    }
  };

  return (
    <div
      className="clinicianPreviewPage"
      style={{
        marginBottom: "1rem",
        marginTop: "calc(calc(3rem + 1.5vw) + 1vw)",
        padding: "0 20px",
      }}
    >
      <LoaderComponent isLoading={isLoading} />

      <div className="pageTitle"> Clinician</div>

      <div className="navFilter">
        {/* <div className="box colSpan1">
          <p className="head">Show</p>
          <SelectComponent
            OptionsArray={[10, 20, 30]}
            fn={generalHandleValueChanges}
            valueData={navItems.showItems}
            setvalue={setNavItems}
            name={"showItems"}
          />
        </div> */}
        {/* <div className="box colSpan1">
          <p className="head">Status</p>
          <SelectComponent
            OptionsArray={["Active", "Inactive"]}
            fn={generalHandleValueChanges}
            valueData={navItems.status}
            setvalue={setNavItems}
            name={"status"}
          />
        </div> */}
        <div className="box colSpan1">
          <p className="head">Search by Name:</p>
          <input type="search" onChange={handleSearch} />
        </div>
        <IconButton
          style={{
            position: "absolute",
            right: "2%",
            top: "19%",
            borderRadius: "4px",
            padding: "1rem",
            fontSize: "  1.4rem",
            color: "#fff",
            fontWeight: "600",
            backgroundColor: "#7A5DDA",
          }}
          className="addPatients"
          onClick={() => navigate("/clinician-preview/clinician")}
        >
          <Icon icon="uil:plus" />
          Add Clinician
        </IconButton>
      </div>
      <TableContainer sx={useStyles.TableContainer} component={Paper}>
        <Table stickyHeader>
          <TableHead sx={useStyles.tableHead}>
            <TableRow>
              <TableCell sx={useStyles.tableHeaderTitle}>S.no</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Clinician Name
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Phone Number
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Speciality</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Email</TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>
                Preferred Communication
              </TableCell>
              <TableCell sx={useStyles.tableHeaderTitle}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.ProviderId}>
                <TableCell sx={useStyles.tableBodyContent}>
                  {index + 1}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row.Name}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Phone Number"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row.Speciality}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row.Email}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  {row["Preferred Communication"]}
                </TableCell>
                <TableCell sx={useStyles.tableBodyContent}>
                  <div
                    className="buttonsContainer"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <IconButton onClick={() => onClickViewBtn(row.ClinicianId)}>
                      <Icon
                        icon="gridicons:fullscreen"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    </IconButton>
                    <IconButton onClick={() => onClickEditBtn(row.ClinicianId)}>
                      <Icon
                        icon="material-symbols:edit-square-outline-sharp"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ClinicianPreviewPage;

import React, { useEffect, useState } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const Prognosis = (props) => {
  const { selectedPatient, id, updateDatafn } = props;

  const [prognosisWindow, setPrognosisWindow] = useState("");
  const addPrognosisDetail = () => {
    setPrognosisWindow("addDetails");
    resetfn(prognosis, setPrognosis);
  };
  const [editArray, setEditArray] = useState();
  const [prognosisArray, setPrognosisArray] = useState([]);
  const [active, setActive] = useState("Active");

  const [prognosis, setPrognosis] = useState({
    RecordedDate: "",
    EndDate: "",
    Notes: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(prognosis)) {
      generalSaveButton(
        prognosisWindow,
        setPrognosisWindow,
        prognosis,
        setPrognosisArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    setPrognosisArray(selectedPatient.Prognosis);
  }, [selectedPatient]);

  useEffect(() => {
    if (
      basicValidation(prognosis) &&
      !areArraysSame(selectedPatient?.Prognosis, prognosisArray)
    ) {
      updateDatafn({ PatientId: id, Prognosis: prognosisArray });
    }
  }, [prognosisArray]);
  return (
    <div>
      <DropDownContent title={"Prognosis"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Prognosis"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addPrognosisDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    prognosisWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setPrognosisWindow),
                },
              ]}
            >
              {" "}
              <div>
                {prognosisWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">Prognosis Information</p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={prognosis.RecordedDate.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setPrognosis,
                                "RecordedDate"
                              )
                            }
                          />

                          <label className="display-element-Gnrl">Notes*</label>
                          <div className="displayDescription-color">
                            <textarea
                              className="display-element-Gnrl"
                              value={prognosis.Notes}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setPrognosis,
                                  "Notes"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={prognosis.EndDate.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setPrognosis,
                                "EndDate"
                              )
                            }
                            min={prognosis.RecordedDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.Prognosis)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Prognosis yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Prognosis"}
                        array={prognosisArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setPrognosisWindow, setPrognosis]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.Prognosis
                        )}
                        dataSet={selectedPatient?.Prognosis ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                          ["End Date & Time:", "EndDate", "colSpan6"],
                          ["Notes:", "Notes", "colSpan2"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.Prognosis)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.Prognosis)}
                    contentArray={[
                      ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Notes:", "Notes", "colSpan2"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}

          {/* <ActiveHistoryDropDown type={"History"} /> */}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Prognosis;

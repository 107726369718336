import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
  getCurrentDateAndTime,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
import { fabClasses } from "@mui/material";

const PreventiveCareAndShedule = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [preventiveCareAndScheduleWindow, setPreventiveCareAndScheduleWindow] =
    useState("");
  const addPreventiveCareAndScheduleDetail = () => {
    setPreventiveCareAndScheduleWindow("addDetails");
    setPreventiveCareAndSchedule({
      ScheduledDate: getCurrentDateAndTime(),
      CompletedDate: "",
      Recommendations:
        "Patient verbalized/ demonstrated understanding of instructions given and agrees with the management plan.The patient agreed to the plan of care. We will contact them in approximately one month for a follow up call per PCC. Patient has been provided with PCC contact information and is aware they can contact me at any time with any questions/concerns regarding her chronic care management.",
      Status: true,
    });
  };

  const [preventiveCareAndScheduleArray, setPreventiveCareAndScheduleArray] =
    useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [preventiveCareAndSchedule, setPreventiveCareAndSchedule] = useState({
    ScheduledDate: getCurrentDateAndTime(),
    CompletedDate: "",
    Recommendations:
      "Patient verbalized/ demonstrated understanding of instructions given and agrees with the management plan.The patient agreed to the plan of care. We will contact them in approximately one month for a follow up call per PCC. Patient has been provided with PCC contact information and is aware they can contact me at any time with any questions/concerns regarding her chronic care management.",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(preventiveCareAndSchedule)) {
      generalSaveButton(
        preventiveCareAndScheduleWindow,
        setPreventiveCareAndScheduleWindow,
        preventiveCareAndSchedule,
        setPreventiveCareAndScheduleArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(preventiveCareAndSchedule) &&
      !areArraysSame(
        selectedPatient?.PreventiveCareAndSchedule,
        preventiveCareAndScheduleArray
      )
    ) {
      updateDatafn({
        PatientId: id,
        PreventiveCareAndSchedule: preventiveCareAndScheduleArray,
      });
    }
  }, [preventiveCareAndScheduleArray]);
  useEffect(() => {
    if (selectedPatient) {
      setPreventiveCareAndScheduleArray(
        selectedPatient.PreventiveCareAndSchedule
      );
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent title={"Preventive Care & Schedule"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Preventive Care & Schedule"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addPreventiveCareAndScheduleDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    preventiveCareAndScheduleWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () =>
                    cancleOperationFn(setPreventiveCareAndScheduleWindow),
                },
              ]}
            >
              {" "}
              <div>
                {preventiveCareAndScheduleWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">
                        Preventive Care & Schedule Information
                      </p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Scheduled Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={preventiveCareAndSchedule.ScheduledDate.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setPreventiveCareAndSchedule,
                                "ScheduledDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            Completed Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={preventiveCareAndSchedule.CompletedDate.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setPreventiveCareAndSchedule,
                                "CompletedDate"
                              )
                            }
                          />
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Description*
                          </label>
                          <div className="displayDescription">
                            <textarea
                              className="display-element-Gnrl"
                              value={preventiveCareAndSchedule.Recommendations}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setPreventiveCareAndSchedule,
                                  "Recommendations"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn(
                      "active",
                      selectedPatient.PreventiveCareAndSchedule
                    )?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any active Preventive Care & Schedule yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"PreventiveCareAndSchedule"}
                        array={preventiveCareAndScheduleArray}
                        id={id}
                        careplanview={careplanview}
                        values={[
                          setPreventiveCareAndScheduleWindow,
                          setPreventiveCareAndSchedule,
                        ]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.PreventiveCareAndSchedule
                        )}
                        dataSet={
                          selectedPatient?.PreventiveCareAndSchedule ?? []
                        }
                        setEditArray={setEditArray}
                        contentArray={[
                          [
                            "Scheduled Date & Time::",
                            "ScheduledDate",
                            "colSpan6",
                          ],
                          [
                            "Completed Date & Time:",
                            "CompletedDate",
                            "colSpan6",
                          ],
                          ["Recommendations:", "Recommendations", "colSpan12"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn(
                  "history",
                  selectedPatient.PreventiveCareAndSchedule
                )?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn(
                      "history",
                      selectedPatient.PreventiveCareAndSchedule
                    )}
                    contentArray={[
                      ["Scheduled Date & Time::", "ScheduledDate", "colSpan6"],
                      ["Completed Date & Time:", "CompletedDate", "colSpan6"],
                      ["Recommendations:", "Recommendations", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default PreventiveCareAndShedule;

import { createSlice } from "@reduxjs/toolkit";

const routingStateSlice = createSlice({
  name: "routingStateSlice",
  initialState: {
    routingDisabled: false,
  },
  reducers: {
    disableRouting(state, action) {
      state.routingDisabled = true;
    },
    enableRouting(state, action) {
      state.routingDisabled = false;
    },
  },
});

export const routingStateActions = routingStateSlice.actions;

export default routingStateSlice.reducer;

import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabElement from "../../../components/TabElement";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const Barriers = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [barriersWindow, setBarriersWindow] = useState("");
  const addBarriersDetail = () => {
    setBarriersWindow("addDetails");
    resetfn(barriers, setBarriers);
  };
  const [barriersArray, setBarriersArray] = useState([]);
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [barriers, setBarriers] = useState({
    RecordedDate: "",
    EndDate: "",
    Recommendations: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(barriers)) {
      generalSaveButton(
        barriersWindow,
        setBarriersWindow,
        barriers,
        setBarriersArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(barriers) &&
      !areArraysSame(selectedPatient?.Barriers, barriersArray)
    ) {
      updateDatafn({ PatientId: id, Barriers: barriersArray });
    }
  }, [barriersArray]);

  useEffect(() => {
    if (selectedPatient) {
      setBarriersArray(selectedPatient.Barriers);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent title={"Barriers"}>
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Barrier"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addBarriersDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    barriersWindow === "addDetails" ? () => onClickFn() : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () => cancleOperationFn(setBarriersWindow),
                },
              ]}
            >
              {" "}
              <div>
                {barriersWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">Barriers Information</p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={barriers.RecordedDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setBarriers,
                                "RecordedDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={barriers.EndDate?.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setBarriers,
                                "EndDate"
                              )
                            }
                            min={barriers.RecordedDate ?? ""}
                          />
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recommendations*
                          </label>
                          <div className="displayDescription">
                            <textarea
                              className="display-element-Gnrl"
                              value={barriers.Recommendations}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setBarriers,
                                  "Recommendations"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn("active", selectedPatient.Barriers)
                      ?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any active barriers yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"Barriers"}
                        array={barriersArray}
                        id={id}
                        careplanview={careplanview}
                        values={[setBarriersWindow, setBarriers]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.Barriers
                        )}
                        dataSet={selectedPatient?.Barriers ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          [
                            "Recorded Date & Time::",
                            "RecordedDate",
                            "colSpan6",
                          ],
                          ["End Date & Time:", "EndDate", "colSpan6"],
                          ["Recommendations:", "Recommendations", "colSpan12"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.Barriers)?.length ===
                0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn("history", selectedPatient.Barriers)}
                    contentArray={[
                      ["Recorded Date & Time::", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Recommendations:", "Recommendations", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default Barriers;

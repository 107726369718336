import React, { useState, useEffect } from "react";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";

const EmergencyAndUrgentNeedsPlan = ({ updateDatafn, selectedPatient, id }) => {
  const [data, setData] = useState(
    "Significant increases in blood pressure (>180/110 mmHg) with symptoms like headache or dizziness.Uncontrolled blood sugar levels (e.g., >300 mg/dL with symptoms).Persistent or worsening musculoskeletal pain despite medications.Signs of infection: fever >100.4°F, redness, swelling, or worsening symptoms at any site.New or worsening digestive symptoms, such as abdominal pain, bloating, or changes in bowel habits."
  );
  const onClickFn = () => {
    updateDatafn({ PatientId: id, "Emergency and Urgent Needs Plan": data });
  };
  useEffect(() => {
    if (selectedPatient) {
      setData(selectedPatient?.["Emergency and Urgent Needs Plan"]);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent
        title={"Emergency and Urgent Needs Plan"}
        children={
          <AddDetailGeneral data={selectedPatient}>
            <>
              <p className="displayTitleGnrl">
                Emergency and Urgent Needs Plan
              </p>
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Emergency and Urgent Needs Plan"}
                iconsList={[
                  {
                    value: "mingcute:save-line",
                    onClickFn: () => onClickFn(),
                  },
                ]}
              />
              <div className="display-sec-Gnrl">
                <div className="display-content-Gnrl display-content-fullwidth">
                  <div className="displayDescription-color">
                    <textarea
                      className="display-element-Gnrl "
                      value={data}
                      onChange={(event) => setData(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>{" "}
          </AddDetailGeneral>
        }
      ></DropDownContent>
    </div>
  );
};

export default EmergencyAndUrgentNeedsPlan;

import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import SelectWithSearch from "../../searchandselect component/SearchAndSelect";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";
const symptonsList = [
  "Abdominal pain",
  "Anemia",
  "Blood spots in the eyes",
  "Blurry Vision",
  "Challenges in planning or solving problems",
  "Changes in mood and personality",
  "Chest Pain",
  "Chest tightness",
  "Clicking or cracking sound when a joint bends",
  "Confusion with time or place",
  "Coughing (especially at night, during exercise or when laughing)",
  "Decreased appetite",
  "Decreased energy, fatigue, feeling 'slowed down'",
  "Decreased or poor judgment",
  "Difficulty completing familiar tasks at home, at work or at leisure",
  "Difficulty in breathing",
  "Dizziness",
  "Dry mouth",
  "Dryness and gum irritation or infection in mouth",
  "Dryness, pain, redness, sensitivity to light and impaired vision in eyes",
  "Facial flushing",
  "Fainted or lost consciousness, even for a short time",
  "Fatigue",
  "Feelings of guilt, worthlessness, helplessness",
  "Feelings of hopelessness, pessimism",
  "Flare",
  "Frequent coughing (with and without sputum)",
  "Frequent urination at night",
  "Headaches",
  "Increased breathlessness",
  "Increased thirst",
  "Inflammation and scarring in lungs",
  "Inflammation of blood vessels",
  "Insomnia, early-morning awakening, or oversleeping",
  "Intense hunger",
  "Joint pain",
  "Lightheadedness",
  "Limited range of motion or stiffness",
  "Loss of appetite",
  "Loss of interest or pleasure in hobbies and activities, including sex",
  "Low appetite and weight loss or overeating and weight gain",
  "Low grade fever",
  "Memory loss that disrupts daily life",
  "Mild swelling around a joint",
  "Misplacing things and losing the ability to retrace steps",
  "Morning headaches",
  "Morning stiffness for 30 minutes or longer",
  "Nausea or vomiting",
  "Night sweats",
  "Nightmares",
  "Pain",
  "Pain and stiffness",
  "Pain that is worse after activity or towards the end of the day",
  "Persistent physical symptoms that do not respond to treatment, such as headaches, digestive disorders and pain for which no other cause can be diagnosed.",
  'Persistent sad, anxious or "empty" mood',
  "Restlessness, irritability",
  "Rheumatoid nodules in skin",
  "Shakiness or weakness",
  "Shortness of breath",
  "Small joints (wrists, certain joints of the hands and feet) are affected",
  "Sore or stiff joints (hips, knees , lower back)",
  "Swelling",
  "Tenderness",
  "Thoughts of death or suicide, suicide attempts",
  "Tightness in the chest",
  "Trouble understanding visual images and spatial relationships",
  "Wheezing",
  "Withdrawal from work or social activities",
  "Difficulty concentrating, remembering, making decisions",
  "Others",
];
const Symptoms = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [symptomsWindow, setSymptomsWindow] = useState("");
  const addSymptomsDetail = () => {
    setSymptomsWindow("addDetails");

    resetfn(symptoms, setSymptoms);
  };
  const [editArray, setEditArray] = useState();
  const [active, setActive] = useState("Active");

  const [symptomsArray, setSymptomsArray] = useState([]);

  const [symptoms, setSymptoms] = useState({
    Symptoms: "",
    RecordedDate: "",
    Duration: "",
    DurationValue: "",
    Notes: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(symptoms)) {
      generalSaveButton(
        symptomsWindow,
        setSymptomsWindow,
        symptoms,
        setSymptomsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  const returnDisplayFn = (dataItem) => {
    return dataItem["DurationValue"] + " " + dataItem["Duration"];
  };

  useEffect(() => {
    if (
      basicValidation(symptoms) &&
      !areArraysSame(selectedPatient?.Symptoms, symptomsArray)
    ) {
      updateDatafn({ PatientId: id, Symptoms: symptomsArray });
    }
  }, [symptomsArray]);
  useEffect(() => {
    setSymptomsArray(selectedPatient.Symptoms);
  }, [selectedPatient]);

  return (
    <div>
      <DropDownContent
        title={"Symptoms"}
        children={
          <div>
            <TabWidget>
              {["Active", "History"]?.map((el) => (
                <TabElement
                  key={`patients_${el}`}
                  title={el}
                  active={active === el}
                  onClick={() => {
                    setActive(el);
                  }}
                />
              ))}
            </TabWidget>
            {active === "Active" && (
              <ActiveHistoryDropDown2
                type={"Active"}
                saveName={"Symptoms"}
                iconsList={[
                  {
                    value: "ic:twotone-plus",
                    onClickFn: addSymptomsDetail,
                  },
                  {
                    value: "mingcute:save-line",
                    onClickFn:
                      symptomsWindow === "addDetails"
                        ? () => onClickFn()
                        : false,
                  },
                  {
                    value: "majesticons:multiply-line",
                    onClickFn: () => cancleOperationFn(setSymptomsWindow),
                  },
                ]}
                children={
                  <div>
                    {symptomsWindow === "addDetails" ? (
                      <AddDetailGeneral data={selectedPatient}>
                        <>
                          <p className="displayTitleGnrl">
                            Symptoms Information
                          </p>
                          <div className="display-sec-Gnrl">
                            <div className="display-content-Gnrl">
                              <label className="display-element-Gnrl">
                                Symptoms*
                              </label>
                              <SelectWithSearch
                                options={symptonsList}
                                valuedata={symptoms.Symptoms}
                                fn={generalHandleValueChanges}
                                setvalue={setSymptoms}
                                name={"Symptoms"}
                              />
                              {/* <select
                             className="display-element-Gnrl"
                             value={symptoms.Symptoms}
                             onChange={(event) =>
                               generalHandleValueChanges(
                                 event,
                                 setSymptoms,
                                 "Symptoms"
                               )
                             }
                           >
                             <option className="default" value=""></option>

                             {symptonsList.map((el) => (
                               <option value={el}>{el}</option>
                             ))}
                           </select> */}

                              <label className="display-element-Gnrl">
                                Recorded Date & Time*
                              </label>
                              <input
                                type="datetime-local"
                                id="meeting-time"
                                name="meeting-time"
                                // value={"2023-06-22T16:25"}
                                value={symptoms.RecordedDate.slice(0, 16)}
                                className="display-element-Gnrl "
                                onChange={(event) =>
                                  generalHandleValueChanges(
                                    event,
                                    setSymptoms,
                                    "RecordedDate"
                                  )
                                }
                              />

                              <div className="display-element-Gnrl doubleSideElement ">
                                <div>
                                  <label className="display-element-Gnrl ">
                                    Duration*
                                  </label>
                                  <select
                                    className="display-element-Gnrl"
                                    value={symptoms.Duration}
                                    onChange={(event) =>
                                      generalHandleValueChanges(
                                        event,
                                        setSymptoms,
                                        "Duration"
                                      )
                                    }
                                  >
                                    <option
                                      className="default"
                                      value=""
                                    ></option>
                                    <option value="Day(s)">Day(s)</option>
                                    <option value="Weeks(s)">Weeks(s)</option>
                                    <option value="Months(s)">Months(s)</option>
                                    <option value="Year(s)">Year(s)</option>
                                  </select>
                                </div>
                                <div>
                                  <label className="display-element-Gnrl ">
                                    fjfjf
                                  </label>
                                  <input
                                    type="number"
                                    className="display-element-Gnrl "
                                    value={symptoms.DurationValue}
                                    onChange={(event) =>
                                      generalHandleValueChanges(
                                        event,
                                        setSymptoms,
                                        "DurationValue"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="display-content-Gnrl">
                              <label className="display-element-Gnrl">
                                Notes*
                              </label>
                              <div className="displayDescription-color">
                                <textarea
                                  className="display-element-Gnrl"
                                  value={symptoms.Notes}
                                  onChange={(event) =>
                                    generalHandleValueChanges(
                                      event,
                                      setSymptoms,
                                      "Notes"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>{" "}
                      </AddDetailGeneral>
                    ) : (
                      <>
                        {statusfinderFn("active", selectedPatient.Symptoms)
                          ?.length === 0 ? (
                          <CarePlanErrorMessage msg="Patient doesn’t have any Symptoms yet!" />
                        ) : (
                          <CarePlanDisplayGeneral
                            updateDatafn={updateDatafn}
                            name={"Symptoms"}
                            array={symptomsArray}
                            id={id}
                            careplanview={careplanview}
                            values={[setSymptomsWindow, setSymptoms]}
                            data={statusfinderFn(
                              "active",
                              selectedPatient.Symptoms
                            )}
                            dataSet={selectedPatient?.selectedPatient ?? []}
                            setEditArray={setEditArray}
                            contentArray={[
                              ["Symptoms", "Symptoms", "colSpan12"],
                              [
                                "Recorded Date & Time:",
                                "RecordedDate",
                                "colSpan6",
                              ],
                              ["Duration:", returnDisplayFn, "colSpan6", true],
                              ["Notes:", "Notes", "colSpan2"],
                            ]}
                          />
                        )}
                      </>
                    )}
                  </div>
                }
              />
            )}
            {active === "History" && (
              <ActiveHistoryDropDown2
                type={"History"}
                children={
                  <>
                    {" "}
                    {statusfinderFn("history", selectedPatient.Symptoms)
                      ?.length === 0 ? (
                      <></>
                    ) : (
                      <CarePlanDisplayGeneral
                        Type={"history"}
                        data={statusfinderFn(
                          "history",
                          selectedPatient.Symptoms
                        )}
                        contentArray={[
                          ["Symptoms", "Symptoms", "colSpan12"],
                          ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                          ["Duration:", "Duration", "colSpan6"],
                          ["Notes:", "Notes", "colSpan2"],
                        ]}
                      />
                    )}
                  </>
                }
              />
            )}
          </div>
        }
      />
    </div>
  );
};

export default Symptoms;

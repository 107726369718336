import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import "./SearchAndSelect.scss";
import classNames from "classnames";
import { useEffect, useMemo } from "react";

const SelectSearch = ({
  disabled,
  optionsDict,
  valuedata,
  fn,
  setvalue,
  name,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const filteredOptions = useMemo(() => {
    if (optionsDict) {
      return optionsDict?.filter((option) =>
        option[0].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [optionsDict, searchTerm]);
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleOptionClick = (option, option2) => {
    if (optionsDict) {
      setSelectedOption(option2);
      setSearchTerm(option2);
      setIsOpen(false);
      fn(option, setvalue, name);
      return;
    }

    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    fn(option, setvalue, name);
  };
  const firstClick = () => {
    setIsOpen(true);
  };
  const clear = () => {
    setIsOpen(true);
    fn(optionsDict[0][1], setvalue, name);

    setSearchTerm("");
    setSelectedOption("");
  };

  return (
    <div
      className={classNames(
        "select-with-search",
        disabled ? "disabledSelector" : ""
      )}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropdown">
          <div className="dropFlex">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              onClick={!disabled ? firstClick : null}
            />
            <button className="close" onClick={clear}>
              {selectedOption ? (
                <Icon icon="iconoir:cancel" />
              ) : (
                <Icon icon="ic:sharp-search" />
              )}
            </button>
          </div>

          {isOpen && optionsDict && (
            <ul className="options" size="5">
              {filteredOptions?.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option[1], option[0])}
                >
                  {option[0]}
                </li>
              ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SelectWithSearchForIdValues = React.memo(SelectSearch);
export default SelectWithSearchForIdValues;

import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Icon } from "@iconify/react";
import "./SearchAndSelect.scss";
import classNames from "classnames";
import { useEffect, useMemo } from "react";

const SelectSearch = ({
  disabled,
  options,
  optionsDict,
  valuedata,
  fn,
  setvalue,
  name,
  ApiOption,
  Apicallfn,
  selectedPatient,
  decription,
  icdfn,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const filteredOptions = useMemo(() => {
    if (ApiOption) {
      return options?.filter((option) =>
        option.full.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (options) {
      return options?.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (optionsDict) {
      return optionsDict?.filter((option) =>
        option[0].toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [options, searchTerm, ApiOption, optionsDict]);

  const handleInputChange = (e) => {
    if (ApiOption) {
      if (e.target.value.length >= 2) {
        Apicallfn(e.target.value);
      }
    }
    setSearchTerm(e.target.value);
  };
  const handleOptionClick = (option, option2) => {
    if (optionsDict) {
      setSelectedOption(option2);
      setSearchTerm(option2);
      setIsOpen(false);
      fn(option, setvalue, name);
      return;
    }
    if (ApiOption) {
      setSelectedOption(option.full.split(" ")[0]);
      setSearchTerm(option.full.split(" ")[0]);
      setIsOpen(false);
      fn(option.full.split(" ")[0], setvalue, name);
      fn(option.full, setvalue, decription);
      if (icdfn) {
        icdfn(option.full.split(" ")[0]);
      }
      return;
    }
    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    fn(option, setvalue, name);
  };
  const firstClick = () => {
    setIsOpen(true);
  };
  const clear = () => {
    setIsOpen(true);
    setSearchTerm("");
    setSelectedOption("");
    if (ApiOption) {
      Apicallfn();
    }
  };
  useEffect(() => {
    if (!valuedata) return;
    if (ApiOption && !selectedPatient) return;
    if (ApiOption && selectedPatient) {
      setSelectedOption(valuedata.split(" ")[0]);
      setSearchTerm(valuedata.split(" ")[0]);
      if (icdfn) {
        icdfn(valuedata.split(" ")[0]);
      }

      return;
    }
    setSelectedOption(valuedata);
    setSearchTerm(valuedata);
  }, [valuedata]);
  return (
    <div
      className={classNames(
        "select-with-search",
        disabled ? "disabledSelector" : ""
      )}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="dropdown">
          <div className="dropFlex">
            <input
              type="text"
              disabled={disabled}
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              onClick={!disabled ? firstClick : null}
            />
            {!disabled && (
              <button className="close" onClick={clear}>
                {selectedOption ? (
                  <Icon icon="iconoir:cancel" />
                ) : (
                  <Icon icon="ic:sharp-search" />
                )}
              </button>
            )}
          </div>
          {isOpen && !ApiOption && !optionsDict && (
            <ul className="options">
              {filteredOptions?.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
          {isOpen && ApiOption && !optionsDict && (
            <ul className="options" size="5">
              {filteredOptions?.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option.full}
                </li>
              ))}
            </ul>
          )}
          {isOpen && optionsDict && (
            <ul className="options" size="5">
              {filteredOptions?.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionClick(option[1], option[0])}
                >
                  {option[0]}
                </li>
              ))}
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const SelectWithSearch = React.memo(SelectSearch);
export default SelectWithSearch;

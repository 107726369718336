import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { FOLD } from "../app/features/navigationSlice";
import { useSelector, useDispatch } from "react-redux";
import { navigationActions } from "../app/features/navigationSlice";
import classNames from "classnames";
import FloatingNotifications from "../utilities/FloatingNotifications";
import "./sideBarElements.scss";

const SideBarElements = ({ title, type, Icon, navigateTo, navigateState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openedSection = useSelector(
    (state) => state.navigationData.openedSection
  );
  const routingDisabled =
    useSelector((state) => state?.routingState?.routingDisabled) ?? false;

  const onTitleClick = () => {
    if (title === openedSection[type]) return;
    dispatch(navigationActions.updateOpenedSection({ type, data: title }));
    if (routingDisabled) {
      FloatingNotifications("error", "Timer is still running");
      return;
    }
    if (navigateTo) {
      navigate(navigateTo, navigateState ?? {});
      dispatch(navigationActions.closeNavBar());
    }
  };
  return (
    <div
      className={classNames("sideBarElements", {
        "first-fold-props": type === FOLD[1],
        "second-fold-props": type === FOLD[2],
        currentlySelected: openedSection[type] === title,
      })}
      onClick={onTitleClick}
    >
      <div className="title">
        {Icon && <Icon className="icon" />}
        <div>{title}</div>
      </div>
    </div>
  );
};

export default SideBarElements;

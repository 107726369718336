import React from "react";
import { useState } from "react";
import { Icon } from "@iconify/react";
import Performance from "./Performance";
import PercentageCompnt from "./PercentageCompnt.jsx";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi.js";
import "./dashboard.scss";

function DashBoard() {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const { data: fpcList } = useGetPatientsOptionsQuery();

  const viewPerformance = () => {
    setPopUpOpen(true);
  };
  return (
    <div className="dashBoard">
      <div className="pageTitle">RPM Dashboard</div>
      <Performance popUpOpen={popUpOpen} setPopUpOpen={setPopUpOpen} />
      <div className="count">
        <div className="countDiv">
          <p className="countItem">
            Total Care Group Active Patients -<span>297</span>
          </p>
          <p className="countItem">
            Total Care Group RPM Patients - <span>297</span>
          </p>
          <p className="countItem">
            RPM Patients Without Device Count-<span>297</span>
          </p>
        </div>
      </div>
      <div className="section1">
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="simple-line-icons:calender" width="18" height="18" />
              <p className="title">RPM Reading Taken Days for </p>
            </div>
            <button className="icons">
              <Icon icon="uil:setting" color="white" width="18" height="18" />
            </button>
          </div>
          <div className="compoBody">
            <div className="RpmReading">
              <div className=" main redBox">
                <div className="Box main-Box">
                  <p className="txt main-txt">DAYS LEFT</p>
                  <p className="num main-num">00</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">Not Started</p>
                </div>
              </div>

              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">01-03 Days</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">04-06 Days</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">07-09 Days</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">10-12 Days</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">13-15 Days</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <p className="num">00</p>
                  <p className="txt">16 Days & above</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon
                icon="mdi:heart-cog"
                color="#505050"
                width="18"
                height="18"
              />
              <p className="title">RPM Missed Vital Readings Count </p>
            </div>
            <button className="icons">
              <Icon icon="uil:setting" color="white" width="18" height="18" />
            </button>
          </div>
          <div className="compoBody">
            <div className="left-rigth">
              <div className="left">
                <p className="txt">No Readings</p>
                <p className="num">00</p>
                <p className="txt-lite">
                  Missed Reading Count for{" "}
                  <span className="txt">Past 00 Days</span>
                </p>
              </div>
              <div className="rigth">
                <p className="rigth-txt">
                  Total Patients with Active Devices :<span>00</span>
                  <br />
                  (at least one vital){" "}
                </p>
                <select id="myDropdown">
                  <option value="">No Readings</option>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
                <select id="myDropdown">
                  <option value="">Past 3 Days</option>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section1 splSection">
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="ic:baseline-phone" width="18" height="18" />
              <p className="title">RPM Service Time Status </p>
            </div>
            <button className="icons">
              <Icon icon="el:download" color="white" width="18" height="18" />
            </button>
          </div>
          <div className="compoBody">
            <div className="RpmReading">
              <div className=" main blueBox">
                <div className="Box main-Box">
                  <p className="txt main-txt">ENROLLED PATIENTS</p>
                  <p className="num main-num">00</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt value={75} color={"#7A5DDA"} />

                  <p className="txt2">Not Started</p>
                </div>
              </div>

              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt color={"#F8758B"} value={50} />

                  <p className="txt2">00:01-10:00 (Min:Sec)</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt color={"#EFCD1B"} value={85} />
                  <p className="txt2">10:01-19:59 (Min:Sec)</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt color={"#2BD27C"} value={20} />
                  <p className="txt2">20:00-39:59 (Min:Sec)</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt color={"#FFA56E"} value={80} />
                  <p className="txt2">40:00-59:59 (Min:Sec)</p>
                </div>
              </div>
              <div className="readingBox">
                <div className="Box">
                  <PercentageCompnt color={"#4A92D4"} value={70} />
                  <p className="txt2">60:00 & above (Min:Sec)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon
                icon="streamline:mail-send-envelope-envelope-email-message-unopened-sealed-close"
                color="#505050"
                width="18"
                height="18"
              />
              <p className="title">RPM Vital Alert </p>
            </div>
            <button className="icons">
              <Icon icon="uil:setting" color="white" width="18" height="18" />
            </button>
          </div>
          <div className="compoBody">
            <div className="rpmalert">
              <div className="alert-box">
                <p className="action">Action Taken</p>
                <div className="timeDisplay">
                  <div className="time">
                    <p className="number">00</p>
                    <p>Without 48 Hrs</p>
                  </div>
                  <div className="line"></div>
                  <div className="time">
                    <p className="number">00</p>
                    <p>Without 48 Hrs</p>
                  </div>
                </div>
              </div>
              <div className="alert-box">
                <p className="action">No Action Taken</p>
                <div className="timeDisplay">
                  <div className="time">
                    <p className="number">00</p>
                    <p>Without 48 Hrs</p>
                  </div>
                  <div className="line"></div>

                  <div className="time">
                    <p className="number">00</p>
                    <p>Without 48 Hrs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section2">
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="bxs:down-arrow" />
              <p className="title">
                Current Month Billable Minutes By Call Attempts{" "}
              </p>
            </div>
          </div>
          <div className="compoBody">
            <div className="gridComponent grid1">
              <div className="gridHead">Call Attempts</div>
              <div className="gridHead">00:00 (Min:Sec)</div>
              <div className="gridHead">00:01-10:00 (Min:Sec)</div>
              <div className="gridHead">10:01-19:59 (Min:Sec)</div>
              <div className="gridHead">20:00-39:59 (Min:Sec)</div>
              <div className="gridHead">40:00-59:59 (Min:Sec)</div>
              <div className="gridHead">60:00 & above (Min:Sec)</div>
            </div>
          </div>
        </div>
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="bxs:down-arrow" />
              <p className="title">Unreachable Patient Count By Days </p>
            </div>
          </div>
          <div className="compoBody">
            <div className="gridComponent grid2">
              <div className="gridHead">Unreachable Attempts</div>
              <div className="gridHead">0 to 30 Days</div>
              <div className="gridHead">31 to 60 Days</div>
              <div className="gridHead">61 to 90 Days</div>
              <div className="gridHead">91 to 120 Days</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="bxs:down-arrow" />
              <p className="title">Clinicians Performance </p>
            </div>
            <button className="icons">
              <Icon icon="el:download" color="white" width="18" height="18" />
              <p className="btntext">
                Download all Clinicians Performance Report
              </p>
            </button>
          </div>
          <div className="compoBody">
            <div className="gridComponent  grid3">
              <div className="gridHead">S.No</div>
              <div className="gridHead">Name</div>
              <div className="gridHead">Action</div>
              {fpcList?.ClinicianList?.map((clinician, index) => (
                <>
                  <div className="gridbody">{index + 1}</div>
                  <div className="gridbody">{clinician?.ClinicianName}</div>
                  <div className="gridbody">
                    <button
                      className="johnBtn"
                      onClick={() => viewPerformance()}
                    >
                      View Performance
                    </button>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="bxs:down-arrow" />
              <p className="title">Physicians Performance </p>
            </div>
            <button className="icons">
              <Icon icon="el:download" color="white" width="18" height="18" />
              <p className="btntext">
                Download all Physicians Performance Report{" "}
              </p>
            </button>
          </div>
          <div className="compoBody">
            <div className="gridComponent grid3">
              <div className="gridHead">S.No</div>
              <div className="gridHead">Name</div>
              <div className="gridHead">Action</div>
              {fpcList?.ProviderList?.map((provider, index) => (
                <>
                  <div className="gridbody">{index + 1}</div>
                  <div className="gridbody">{provider?.ProviderName}</div>
                  <div className="gridbody">
                    <button
                      className="johnBtn"
                      onClick={() => viewPerformance()}
                    >
                      View Performance
                    </button>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="DashCompo">
          <div className="compoTitle">
            <div className="title-icon">
              <Icon icon="bxs:down-arrow" />
              <p className="title">Text Message Activation Status </p>
            </div>
          </div>
          <div className="compoBody">
            <div className="inputtypeDisplay">
              Patients Without Mobile Number : <span>00</span>
            </div>
            <div className="inputtypeDisplay">
              Patients With Mobile Number : <span>00</span>
            </div>
            <div className="textMessageBox">
              <p className="num">00</p>
              <p className="txt">Mobile Phone Numbers Not Verified</p>
            </div>
            <div className="subTextBox">
              <div className="textMessageBox">
                <p className="num">00</p>
                <p className="txt">Patients Opted-In for Text Messages</p>
              </div>
              <div className="textMessageBox">
                <p className="num">00</p>
                <p className="txt">Patients Opted-Out of Text Messages</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;

import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router";
import { logOut } from "./app/features/authSlice";
import ErrorBoundary from "./ErrorBoundary.jsx";
import RequiredAuth from "./components/RequiredAuth";
import ServiceHistory from "./servicehistory/ServiceHistory.jsx";
import ServiceLog from "./serviceLog/ServiceLog.jsx";
import LoginLayout from "./LoginComponents/LoginLayout";
import Facility from "./clinical/facility/Facility";
import PatientViewPage from "./patients/patientViewPage/PatientsViewPage";
import Provider from "./clinical/provider/Provider";
import Clinician from "./clinical/clinician/Clinician";
import Patients from "./patients/Patients.jsx";
import Maintenance from "./components/Maintenance";
import { NotificationContainer } from "react-notifications";
import PatientsPreviewPage from "./patients/parientsPreviewPage/PatientsPreviewPage";
// import PatientsPreviewPage from "./patients/parientsPreviewPage/PatientsPreviewPage";
import CareTeam from "./patients/Careteam/CareTeam";
import CallTaskList from "./callTaskList/CallTaskList.jsx";
import TransferPatients from "./adminpages/TransferPatients.jsx";
import "./sass/utility.scss";
import FacilityPreviewPage from "./clinical/facility/FacilityPreviewPage";
import CallingMenu from "./patients/Careteam/CallingMenu/CallingMenu";
import FacilityView from "./clinical/facility/FacilityView";
import RpmView from "./patients/rpmview/RpmView";
import DashBoard from "./dashboard/DashBoard";
import Billing from "./billing/Billing";
import CcmDashBoard from "./dashboard/CcmDashboard";
import ListEligiblePatients from "./list-eligible-patients/ListEligiblePatients";
import ScheduleCalls from "./schedule/ScheduleCalls";
import ViewCalendar from "./schedule/ViewCalendar";
import ProviderPreviewPage from "./clinical/provider/ProviderPreviewPage";
import ClinicianPreviewPage from "./clinical/clinician/ClinicianPreview";
import LoadEligiblePatients from "./load-eligible-patients/LoadEligiblePatients";
import ResendLoginCredentials from "./adminpages/ResendLoginCredentials";
import ActivateAndDeactivatePatients from "./adminpages/ActivateAndDeactivatePatients";
import ActivateAndDeactivateUser from "./adminpages/ActivateAndDeactivateUser";
import DeviceList from "./rpmDevicesAndReading/DeviceList.jsx";
import VitalReadingList from "./rpmDevicesAndReading/VitalReadingList.jsx";
import AdminPage from "./adminpages/AdminPage.jsx";
import UnmapedData from "./rpmDevicesAndReading/UnmapedData.jsx";
function App() {
  const authData = useSelector((state) => state.authData);
  const [expiryTimeData, setExpiryTimeData] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    const intervalInstance = setInterval(() => {
      if (expiryTimeData) {
        if (expiryTimeData < new Date().getTime() / 1000) {
          dispatch(logOut());
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalInstance);
    };
  }, [expiryTimeData]);

  useEffect(() => {
    if (authData.token) {
      let temp_decoded_data = jwt_decode(authData.token);
      if (temp_decoded_data) {
        setExpiryTimeData(temp_decoded_data.exp);
      }
    } else {
      setExpiryTimeData(null);
    }
  }, [authData.token]);
  return (
    <>
      <ErrorBoundary>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginLayout />} />
            <Route path="/" element={<RequiredAuth />}>
              <Route path="/rpm-DashBoard" element={<DashBoard />} />
              <Route index element={<CcmDashBoard />} />
              <Route
                path="facility-preview"
                element={<FacilityPreviewPage />}
              />
              <Route
                path="provider-preview"
                element={<ProviderPreviewPage />}
              />
              <Route
                path="clinician-preview"
                element={<ClinicianPreviewPage />}
              />
              <Route path="facility-preview/facility" element={<Facility />} />
              <Route
                path="facility-preview/facility-view"
                element={<FacilityView />}
              />
              <Route path="provider-preview/provider" element={<Provider />} />
              <Route
                path="clinician-preview/clinician"
                element={<Clinician />}
              />
              <Route path="patients/Add-Patient" element={<Patients />} />
              <Route
                path="patients/View-Patient"
                element={<PatientViewPage />}
              />
              <Route path="patients/careplan" element={<CareTeam />} />
              <Route path="patients" element={<PatientsPreviewPage />} />
              <Route path="callTask" element={<CallTaskList />} />
              <Route path="transfer-patients" element={<TransferPatients />} />
              <Route path="service-Log" element={<ServiceLog />} />
              {/* <Route path="service-History" element={<ServiceHistory />} /> */}
              <Route path="admin" element={<AdminPage />} />

              <Route
                path="patients/list-Eligible-Patients"
                element={<ListEligiblePatients />}
              />
              <Route
                path="patients/load-Eligible-Patients"
                element={<LoadEligiblePatients />}
              />
              <Route path="patients/careplan-Call" element={<CallingMenu />} />
              <Route path="patients/rpm" element={<RpmView />} />
              <Route
                path="patients/rpm/unmaped-data"
                element={<UnmapedData />}
              />
              <Route path="dashboard" element={<CcmDashBoard />} />
              <Route path="billing" element={<Billing />} />
              <Route path="scheduleCalls" element={<ScheduleCalls />} />
              <Route path="viewCalendar" element={<ViewCalendar />} />
              <Route
                path="rpm-vital-reading-list"
                element={<VitalReadingList />}
              />
              <Route path="rpm-device-list" element={<DeviceList />} />
              <Route
                path="resend-Login-Credentials"
                element={<ResendLoginCredentials />}
              />
              <Route
                path="user-Actions"
                element={<ActivateAndDeactivateUser />}
              />
              <Route
                path="patient-Actions"
                element={<ActivateAndDeactivatePatients />}
              />
            </Route>
            <Route path="*" element={<Maintenance />} />
          </Routes>
        </div>
      </ErrorBoundary>

      <NotificationContainer />
    </>
  );
}

export default App;

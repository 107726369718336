import React, { useState, useEffect } from "react";
import Stopwatch from "../../../components/StopWatch";
import { Icon } from "@iconify/react";
import "../../../sass/callingTimer.scss";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const CallingTimer = ({
  programName,
  id,
  startTimer,
  setStartTimer,
  type,
  timerFn,
  totalTime,
  timerStatus,
  disabled,
  elem,
}) => {
  const [timer, setTimer] = useState("");
  const [elapsedTime, setElapsedTime] = useState(0);
  const handlestopWatch = (data, reset) => {
    setTimer(data);
    if (reset) {
      setElapsedTime(0);
    }
  };
  function utcToNormalDateTime(timestamp) {
    const utcDate = new Date(timestamp);

    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();

    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    const seconds = utcDate.getUTCSeconds();

    const amOrPm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 || 12;

    const formattedDateTime = `${year}-${String(month).padStart(
      2,
      "0"
    )}-${String(day).padStart(2, "0")} ${formattedHours}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${amOrPm}`;

    return formattedDateTime;
  }
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const parseTime = (formattedTime) => {
    if (!formattedTime) return 0;
    const [hours, minutes, seconds] = formattedTime?.split(":")?.map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  useEffect(() => {
    let intervalId;
    if (timer) {
      // setButtonClick((prev) => prev + 1);
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);
  return (
    <div className="CallingTimer">
      <div className="left">
        <div className="top first">
          <p className="head">
            {disabled
              ? " "
              : ` Previous ${programName} serviced time for the month`}
          </p>
          <p className="time">{disabled ? " " : totalTime ?? "00:00:00"} </p>
        </div>
        <div className="top second">
          <p className="head">
            {disabled
              ? `Total service time for the month ${
                  months[new Date(elem?.services?.[0]?.startTime).getUTCMonth()]
                } `
              : "Total service time for the month"}
          </p>
          <p className="time">
            {disabled
              ? elem?.totalTimeSpent
              : formatTime(parseTime(totalTime) + elapsedTime)}

            {/* {formatTime(parseTime(totalTime) + elapsedTime)} */}
            {/* {timerStatus?.serviceLog?.acutalTimeSpent ?? "00:00:00"} */}
          </p>
        </div>
      </div>
      {!disabled && (
        <div className="rigth">
          <div className="headTitle">
            {(() => {
              switch (type) {
                case "Phone":
                  return (
                    <>
                      <Icon icon="ion:call" />
                      <p>Call Duration</p>
                    </>
                  );
                case "In-Person":
                  return (
                    <>
                      <Icon icon="fa6-solid:user-doctor" />{" "}
                      <p>In-Person Visit</p>{" "}
                    </>
                  );
                case "Documentation":
                  return (
                    <>
                      <Icon icon="mingcute:paper-line" />{" "}
                      <p>Call Documentation</p>{" "}
                    </>
                  );
                default:
                  return <p>Default Content</p>;
              }
            })()}
          </div>
          <Stopwatch
            id={id}
            startTimer={startTimer}
            setStartTimer={setStartTimer}
            // programName={programName.programName}
            // serviceName={"Care Plan Entry"}
            // totalTime={totalTime}
            timerFn={timerFn}
            callingpage={true}
            programName={"CCM"}
            serviceName={"monthly call"}
            handlestopWatch={handlestopWatch}
            // totalTime={totalTime}
            // updateFunction={updateFunction}
          />
        </div>
      )}
      {disabled && (
        <div className="serviceData ">
          <div className="gridBody">
            <div className="gridHeader">
              <div className="gridHead">Service Name</div>
              <div className="gridHead">Service Type</div>
              <div className="gridHead">Service Date & Time</div>
              <div className="gridHead">Performed By</div>
              <div className="gridHead"> Time Spent (HH:MI:SS)</div>
            </div>
            <div className="gridContent">
              <>
                {elem?.services?.map((log) => (
                  <>
                    <div className="gridBox"> {log?.serviceName}</div>
                    <div className="gridBox"> {log?.serviceType}</div>
                    <div className="gridBox">
                      {" "}
                      {utcToNormalDateTime(log?.startTime)}
                    </div>
                    <div className="gridBox">
                      {" "}
                      {log?.serviceProviderProfile?.FullName}
                    </div>
                    <div className="gridBox"> {log?.acutalTimeSpent}</div>
                  </>
                ))}
              </>
            </div>
          </div>
          <div className="totalcpmd">
            <p className="total colSpan4">Total</p>
            <p className="totalTime colSpan2">{elem?.totalTimeSpent}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallingTimer;

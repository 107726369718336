import React, { useEffect, useState } from "react";
import SelectComponent from "../components/SelectComponent";
import DateRangePicker from "rsuite/DateRangePicker";
// import "rsuite/dist/rsuite.min.css";
import IconButton from "@mui/material/IconButton";

import "rsuite/DateRangePicker/styles/index.css";
import { addDays } from "date-fns";
import FloatingNotifications from "../utilities/FloatingNotifications";
import { useGetServiceLogMutation } from "../app/api/billingApi";
import TabWidget2 from "../components/TabWidget";
import RemarksViewer from "../components/RemarksViewer";
import TabElement2 from "../components/TabElement";
import { utils, writeFile } from "xlsx";
import LoaderComponent from "../components/LoaderComponent";
import { Icon } from "@iconify/react";
import { useDeleteServiceMutation } from "../app/api/billingApi";
import "./serviceLog.scss";
import { generalHandleValueChanges } from "../patients/Careteam/helperfunctions";
import { useCallback } from "react";
import SelectComponent2 from "../components/SelectCompoent2";
import PopUp from "../patients/patientViewPage/PopUp";
import { useGetPatientsOptionsQuery } from "../app/api/patientSelctorOptionApi";
import { Start } from "@mui/icons-material";
import TimeSpentInput from "../components/SelectTimeSpentComp";
import { useGetPatientsAllProfilesQuery } from "../app/api/patientsApi";
import SelectWithSearchForIdValues from "../patients/searchandselect component/SearchSelectForIdValues";
const servicesTypeList = ["Detail View", "Summary View"];
function utcToNormalDateTime(timestamp) {
  const utcDate = new Date(timestamp);

  const year = utcDate.getUTCFullYear();
  const month = utcDate.getUTCMonth() + 1;
  const day = utcDate.getUTCDate();

  const hours = utcDate.getUTCHours();
  const minutes = utcDate.getUTCMinutes();
  const seconds = utcDate.getUTCSeconds();

  const amOrPm = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;

  const formattedDateTime = `${year}-${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")} ${formattedHours}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")} ${amOrPm}`;

  return formattedDateTime;
}

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "Last 7 Days",
    value: [addDays(new Date(), -7), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 Days",
    value: [addDays(new Date(), -30), new Date()],
    placement: "left",
  },
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ServiceLog = () => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [patientDataForService, setPatientDataForService] = useState("");
  const [selectedRange, setSelectedRange] = useState({});
  const [serviceToDelete, setServiceToDelete] = useState("");
  const [shedulePopUp, setShedulePopUp] = useState(false);
  const [serviceLog, setServiceLog] = useState([]);
  const [filteredServiceData, setFilteredServiceData] = useState("");
  const [servicesType, setServicesType] = useState("Detail View");

  const servicesTypeHandler = (el) => {
    setServicesType(el);
  };
  const [getServiceLogFn, { data: services, isLoading }] =
    useGetServiceLogMutation(undefined, {
      refetchOnMountOrArgChange: true,
    });
  const { data: dataFromApi, refetch } = useGetPatientsAllProfilesQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const initialState = {
    programName: "CCM",
  };
  const dataFiltersInitialState = {
    facility: "",
    provider: "",
    clinician: "",
    patient: "",
    callStatus: "",
    range: "All",
    lowerRange: "00:20:00",
    higherRange: "",
  };
  const { data: fpcList } = useGetPatientsOptionsQuery();

  const FacilityList =
    fpcList?.FacilityList?.map((facility) => [
      facility.FacilityName,
      facility.FacilityId,
    ]) ?? [];
  FacilityList.unshift(["All", ""]);
  const ProviderList =
    fpcList?.ProviderList?.map((provider) => [
      provider.ProviderName,
      provider.ProviderId,
    ]) ?? [];
  ProviderList.unshift(["All", ""]);
  const ClinicianList =
    fpcList?.ClinicianList?.map((clinician) => [
      clinician.ClinicianName,
      clinician.ClinicianId,
    ]) ?? [];
  ClinicianList.unshift(["All", ""]);
  const PatientList = dataFromApi?.map((patient) => [
    patient.Name,
    patient.PatientId,
  ]);
  PatientList?.unshift(["All", ""]);
  const [filter, setFilter] = useState(initialState);

  const [dataFilters, setDataFilters] = useState(dataFiltersInitialState);
  const [deleteServiceFn, { data: deletedService, isLoadingDelete }] =
    useDeleteServiceMutation();
  const onHandleExport = useCallback(async () => {
    if (!filteredServiceData.length > 0) {
      FloatingNotifications("error", "No data is Found");

      return;
    }
    let data = [];
    await filteredServiceData?.map((patient) =>
      data.push({
        "Serviced By": patient?.serviceProviderName,
        Role: patient?.serviceProviderUser,
        "Tracked By": patient?.serviceType,
        "Service Name": patient?.serviceName,
        "Service Date": patient?.startTime?.slice(0, 10),
        "Service Start time": patient?.startTime?.slice(11, 19),
        "Service End time": patient?.endTime?.slice(0, 10),
        "Manual adjustment time (HH:MI:SS)":
          patient?.serviceType === "Manual" ? patient?.acutalTimeSpent : "",
        "Billable time (HH:MI:SS)": patient?.acutalTimeSpent,
        "Billable hours": patient?.acutalTimeSpent.slice(0, 2),
        "Billable minutes": patient?.acutalTimeSpent.slice(3, 5),
        "Billable seconds": patient?.acutalTimeSpent.slice(6, 8),
        "Call Status": patient?.callStatus,
        "Called Patient / Care Giver Number": patient?.patientInfo?.phone,
        "Patient Name": patient?.patientInfo?.PatientName,
        "Date of Birth": patient?.patientInfo?.DOB,
        "Patient Address": patient?.patientInfo?.PatientAddress,
        "Facility Name": patient?.patientInfo?.facilityInfo?.FacilityName,
        "Care Plan Physician Name":
          patient?.patientInfo?.providerInfo?.ProviderName,
        "Primary Care Coordinator":
          patient?.patientInfo?.coordinatorInfo?.CoordinatorName,

        Remark: patient?.remark,
      })
    );

    const ws = utils.json_to_sheet(data);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    let number = Math.floor(Math.random() * 10000000);
    writeFile(wb, `service-Log-${number}.xlsx`);
  }, [filteredServiceData]);

  const setFilterDefault = () => {
    setDataFilters({
      facility: "",
      provider: "",
      clinician: "",
      patient: "",
      range: "All",
      lowerRange: "",
      higherRange: "",
    });
    setFilter(initialState);
  };

  const getServiceLog = () => {
    getServiceLogFn({
      programName: filter?.programName,
      startTime: new Date(selectedRange?.[0]),
      endTime: new Date(selectedRange?.[1]),
    });
  };

  const cancelfnDel = () => {
    setServiceToDelete("");
    setShedulePopUp(false);
  };
  const onClickDelete = (service) => {
    setServiceToDelete(service);
    setShedulePopUp(true);
  };
  const onClickDeleteConfirmed = () => {
    if (!serviceToDelete) return;
    deleteServiceFn({
      PatientId: serviceToDelete?.PatientId,
      acutalTimeSpent: serviceToDelete?.acutalTimeSpent,
      programName: serviceToDelete?.programName,
      startTime: serviceToDelete?.startTime,
      endTime: serviceToDelete?.endTime,
      remark: serviceToDelete?.remark,
      serviceId: serviceToDelete?.serviceId,
      serviceName: serviceToDelete?.serviceName,
      serviceType: serviceToDelete?.serviceType,
      serviceProviderProfileId: serviceToDelete?.serviceProviderProfileId,
      serviceProviderUser: serviceToDelete?.serviceProviderUser,
    });
    setShedulePopUp(false);
  };

  const getCurrentDateTimeLocal = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0"); // Months are zero-based
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = "00";
    const minutes = "00";
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString?.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  useEffect(() => {
    if (!serviceLog) return;
    let dummyData = serviceLog;
    if (dataFilters.facility !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.facilityInfo?.FacilityId === dataFilters.facility
      );
    }
    if (dataFilters.provider !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.providerInfo?.ProviderId === dataFilters.provider
      );
    }
    if (dataFilters.callStatus !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.callStatus === dataFilters.callStatus
      );
    }
    if (dataFilters.clinician !== "") {
      dummyData = dummyData?.filter(
        (data) =>
          data?.patientInfo?.coordinatorInfo?.CoordinatorId ===
          dataFilters.clinician
      );
    }
    if (dataFilters.patient !== "") {
      dummyData = dummyData?.filter(
        (data) => data?.PatientId === dataFilters.patient
      );
    }
    if (dataFilters.range !== "") {
      switch (dataFilters.range) {
        case "Greater Than":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) >
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Less Than":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) <
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) ===
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Greater Than or Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) >=
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "Less Than or Equal To":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) <=
                timeStringToSeconds(dataFilters?.lowerRange)
              );
            } else {
              return false;
            }
          });
          break;
        case "In Between":
          dummyData = dummyData?.filter((data) => {
            if (data?.acutalTimeSpent && dataFilters?.higherRange) {
              return (
                timeStringToSeconds(data?.acutalTimeSpent) >
                  timeStringToSeconds(dataFilters?.lowerRange) &&
                timeStringToSeconds(data?.acutalTimeSpent) <
                  timeStringToSeconds(dataFilters?.higherRange)
              );
            } else {
              return false;
            }
          });
          break;

        default:
          break;
      }
    }
    setFilteredServiceData(dummyData);
  }, [dataFilters, serviceLog]);
  useEffect(() => {
    if (services) {
      setServiceLog(services);
    }
  }, [services]);
  useEffect(() => {
    getServiceLog();
  }, [filter, selectedRange, deletedService]);
  return (
    <div className="serviceLog">
      {shedulePopUp && (
        <PopUp cancelfn={cancelfnDel}>
          <div>
            <p>Are you sure you want to delete this service Log </p>
            <div className="buttonsContainer">
              <button onClick={onClickDeleteConfirmed}>Delete</button>
              <button onClick={cancelfnDel}>cancel</button>
            </div>
          </div>
        </PopUp>
      )}
      <p className="title">Services Log</p>
      <LoaderComponent isLoading={isLoading} />

      <div className="advanceSearchBox">
        <div className="box">
          <p className="head">Call status</p>
          <SelectComponent
            OptionsArray={[
              "Call completed",
              "Call follow up",
              "Call not answered",
              "Left Message",
              "Do not Call",
              "Wrong number",
            ]}
            fn={generalHandleValueChanges}
            valueData={dataFilters.callStatus}
            setvalue={setDataFilters}
            name={"callStatus"}
          />
        </div>
        <div className="box">
          <p className="head">Facility Name</p>
          <SelectWithSearchForIdValues
            optionsDict={FacilityList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.facility}
            setvalue={setDataFilters}
            name={"facility"}
          />
        </div>
        <div className="box colSpan2">
          <p className="head">Total Time Spent (HH:MI:SS)</p>
          <div className="select2">
            <span>
              <SelectComponent
                OptionsArray={[
                  "All",
                  "Greater Than",
                  "Less Than",
                  "Equal To",
                  "Greater Than or Equal To",
                  "Less Than or Equal To",
                  "In Between",
                ]}
                placeholder="Select Range"
                fn={generalHandleValueChanges}
                valueData={dataFilters.range}
                setvalue={setDataFilters}
                name={"range"}
              />
            </span>
            <span>
              {/* <input
                id="appt-time"
                type="time"
                name="appt-time"
                step="2"
                value={dataFilters?.lowerRange}
                // className="viewElement "
                onChange={(event) =>
                  generalHandleValueChanges(event, setDataFilters, "lowerRange")
                }
              /> */}
              <TimeSpentInput
                value={dataFilters?.lowerRange}
                setService={setDataFilters}
                flagName={"lowerRange"}
              />
            </span>
            {dataFilters?.range === "In Between" && (
              <span>
                {/* <input
                  id="appt-time"
                  type="time"
                  name="appt-time"
                  step="2"
                  value={dataFilters?.higherRange}
                  // className="viewElement "
                  onChange={(event) =>
                    generalHandleValueChanges(
                      event,
                      setDataFilters,
                      "higherRange"
                    )
                  }
                /> */}
                <TimeSpentInput
                  value={dataFilters?.higherRange}
                  setService={setDataFilters}
                  flagName={"higherRange"}
                />
              </span>
            )}
          </div>
        </div>
        <div className="box">
          <p className="head">Care Plan Physician Name</p>
          <SelectWithSearchForIdValues
            optionsDict={ProviderList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.provider}
            setvalue={setDataFilters}
            name={"provider"}
          />
        </div>

        <div className="box">
          <p className="head">Patient Name</p>
          <SelectWithSearchForIdValues
            optionsDict={PatientList}
            fn={generalHandleValueChanges}
            valueData={dataFilters.patient}
            setvalue={setDataFilters}
            name={"patient"}
          />
        </div>

        <div className="box">
          <p className="head">Date range*</p>
          <DateRangePicker
            size={"mid"}
            showOneCalendar
            ranges={predefinedRanges}
            onChange={(range) => setSelectedRange(range)}
            value={selectedRange}
          />
        </div>
        <div className="box">
          <p className="head">Program Name *</p>
          <SelectComponent
            OptionsArray={["CCM", "RPM", "BHI"]}
            valueData={filter.programName}
            fn={generalHandleValueChanges}
            setvalue={setFilter}
            name={"programName"}
          />
        </div>
      </div>
      <div className="buttons">
        <button onClick={setFilterDefault}>Reset</button>
        <button onClick={onHandleExport}>Generate Report</button>
      </div>
      <div className="servicesBody">
        <div className="servicesButtons">
          {/* <button>Generate Report</button> */}
        </div>
        <div className="serviceLogTable">
          <>
            <div className="grid1 header">
              <div className="tableElement colSpan1 header">Role</div>
              <div className="tableElement colSpan1 header">Serviced By</div>

              <div className="tableElement colSpan1 header">Service Name</div>
              <div className="tableElement colSpan1 header">
                Service Date & Time
              </div>
              <div className="tableElement colSpan2 header">
                Duration (HH:MI:SS)
              </div>
              <div className="tableElement colSpan1 header">Call Status</div>
              <div className="tableElement colSpan1 header"> Patient Phone</div>
              <div className="tableElement colSpan1 header"> Patient Name</div>
              <div className="tableElement colSpan1 header"> Facility</div>
              <div className="tableElement colSpan1 header">
                Care Plan Physician{" "}
              </div>
              <div className="tableElement colSpan1 header">Remark </div>
              <div className="tableElement colSpan1 header">Actions </div>

              {/* <div className="tableElement colSpan1"></div> */}
            </div>
            {filteredServiceData &&
              filteredServiceData?.map((elem, index) => (
                <div className="grid1">
                  <div className="tableBodyElement colSpan1">
                    {elem?.serviceProviderUser}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.serviceProviderName}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.serviceName}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.startTime?.slice(0, 10)}-
                    {elem?.startTime?.slice(11, 19)}
                  </div>
                  <div className="tableBodyElement colSpan2">
                    {elem?.acutalTimeSpent}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.callStatus}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.patientInfo?.phone}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.patientInfo?.PatientName}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.patientInfo?.facilityInfo?.FacilityName}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    {elem?.patientInfo?.providerInfo?.ProviderName}
                  </div>
                  <div className="tableBodyElement colSpan1">
                    <RemarksViewer
                      content={{
                        PatientName: elem?.patientInfo?.PatientName,
                        remarks: [
                          {
                            remark: elem?.remark,
                            service: elem?.serviceName,
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="tableBodyElement colSpan1">
                    <IconButton onClick={() => onClickDelete(elem)}>
                      <Icon
                        icon="material-symbols:delete"
                        style={{ height: "2rem", width: "2rem" }}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default ServiceLog;

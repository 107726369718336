import React from "react";
import { useState, useEffect } from "react";
import DropDownContent from "../../../components/DropDownContent";
import ActiveHistoryDropDown2 from "../../../components/ActiveHistoryDropDown2";
import AddDetailGeneral from "../addDetailsGeneral/AddDetailGeneral";
import CarePlanDisplayGeneral from "../CarePlanDisplayGeneral/CarePlanDisplayGeneral";
import TabElement from "../../../components/TabElement";
import FloatingNotifications from "../../../utilities/FloatingNotifications";
import TabWidget from "../../../components/TabWidget";
import {
  resetfn,
  basicValidation,
  areArraysSame,
  careplanview,
  generalHandleValueChanges,
  generalSaveButton,
  cancleOperationFn,
  statusfinderFn,
} from "../helperfunctions";
import CarePlanErrorMessage from "../CarePlanErrorMessage";

const OtherRecommends = (props) => {
  const { selectedPatient, id, updateDatafn } = props;
  const [otherRecommendationsWindow, setOtherRecommendationsWindow] =
    useState("");
  const addOtherRecommendationsDetail = () => {
    setOtherRecommendationsWindow("addDetails");
    resetfn(otherRecommendations, setOtherRecommendations);
  };
  const [editArray, setEditArray] = useState();

  const [otherRecommendationsArray, setOtherRecommendationsArray] = useState(
    selectedPatient?.OtherRecommendations || []
  );
  const [active, setActive] = useState("Active");

  const [otherRecommendations, setOtherRecommendations] = useState({
    Type: "",
    RecordedDate: "",
    EndDate: "",
    Recommendations: "",
    Status: true,
  });
  const onClickFn = () => {
    if (basicValidation(otherRecommendations)) {
      generalSaveButton(
        otherRecommendationsWindow,
        setOtherRecommendationsWindow,
        otherRecommendations,
        setOtherRecommendationsArray,
        editArray,
        setEditArray
      );
    } else {
      FloatingNotifications("error", "Fill mandatory fields to proceed");
    }
  };
  useEffect(() => {
    if (
      basicValidation(otherRecommendations) &&
      !areArraysSame(
        selectedPatient?.OtherRecommendations,
        otherRecommendationsArray
      )
    ) {
      updateDatafn({
        PatientId: id,
        OtherRecommendations: otherRecommendationsArray,
      });
    }
  }, [otherRecommendationsArray]);
  useEffect(() => {
    if (selectedPatient) {
      setOtherRecommendationsArray(selectedPatient.OtherRecommendations);
    }
  }, [selectedPatient]);
  return (
    <div>
      <DropDownContent title={"Other Recommendations"}>
        {" "}
        <div>
          <TabWidget>
            {["Active", "History"]?.map((el) => (
              <TabElement
                key={`patients_${el}`}
                title={el}
                active={active === el}
                onClick={() => {
                  setActive(el);
                }}
              />
            ))}
          </TabWidget>
          {active === "Active" && (
            <ActiveHistoryDropDown2
              type={"Active"}
              saveName={"Other Recommendations"}
              iconsList={[
                {
                  value: "ic:twotone-plus",
                  onClickFn: addOtherRecommendationsDetail,
                },
                {
                  value: "mingcute:save-line",
                  onClickFn:
                    otherRecommendationsWindow === "addDetails"
                      ? () => onClickFn()
                      : false,
                },
                {
                  value: "majesticons:multiply-line",
                  onClickFn: () =>
                    cancleOperationFn(setOtherRecommendationsWindow),
                },
              ]}
            >
              <div>
                {otherRecommendationsWindow === "addDetails" ? (
                  <AddDetailGeneral data={selectedPatient}>
                    <>
                      <p className="displayTitleGnrl">
                        Other Recommendation Informations
                      </p>
                      <div className="display-sec-Gnrl">
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">Type*</label>
                          <select
                            className="display-element-Gnrl"
                            value={otherRecommendations.Type}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setOtherRecommendations,
                                "Type"
                              )
                            }
                          >
                            <option className="default" value=""></option>
                            <option value="Tests to complete">
                              Tests to complete{" "}
                            </option>
                            <option value="Other health professionals to see">
                              Other health professionals to see
                            </option>
                            <option value="Community resources to use">
                              Community resources to use
                            </option>
                            <option value="Medication changes to make">
                              Medication changes to make
                            </option>
                            <option value="Other Treatments to get">
                              Other Treatments to get
                            </option>
                            <option value="Health related education to pursue">
                              Health related education to pursue
                            </option>
                            <option value="Short-term activities to do">
                              Short-term activities to do
                            </option>
                            <option value="Other">Other</option>
                          </select>

                          <label className="display-element-Gnrl">
                            Description*
                          </label>
                          <div className="displayDescription">
                            <textarea
                              className="display-element-Gnrl"
                              value={otherRecommendations.Recommendations}
                              onChange={(event) =>
                                generalHandleValueChanges(
                                  event,
                                  setOtherRecommendations,
                                  "Recommendations"
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="display-content-Gnrl">
                          <label className="display-element-Gnrl">
                            Recorded Date & Time*
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={otherRecommendations.RecordedDate.slice(
                              0,
                              16
                            )}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setOtherRecommendations,
                                "RecordedDate"
                              )
                            }
                          />
                          <label className="display-element-Gnrl">
                            End Date & Time
                          </label>
                          <input
                            className="display-element-Gnrl "
                            type="datetime-local"
                            id="meeting-time"
                            name="meeting-time"
                            value={otherRecommendations.EndDate.slice(0, 16)}
                            onChange={(event) =>
                              generalHandleValueChanges(
                                event,
                                setOtherRecommendations,
                                "EndDate"
                              )
                            }
                            min={otherRecommendations.RecordedDate ?? ""}
                          />
                        </div>
                      </div>
                    </>{" "}
                  </AddDetailGeneral>
                ) : (
                  <>
                    {statusfinderFn(
                      "active",
                      selectedPatient.OtherRecommendations
                    )?.length === 0 ? (
                      <CarePlanErrorMessage msg="Patient doesn’t have any Other Recommendations yet!" />
                    ) : (
                      <CarePlanDisplayGeneral
                        updateDatafn={updateDatafn}
                        name={"OtherRecommendations"}
                        array={otherRecommendationsArray}
                        id={id}
                        careplanview={careplanview}
                        values={[
                          setOtherRecommendationsWindow,
                          setOtherRecommendations,
                        ]}
                        data={statusfinderFn(
                          "active",
                          selectedPatient.OtherRecommendations
                        )}
                        dataSet={selectedPatient?.OtherRecommendations ?? []}
                        setEditArray={setEditArray}
                        contentArray={[
                          ["Type:", "Type", "colSpan12"],
                          ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                          ["End Date & Time:", "EndDate", "colSpan6"],
                          ["Recommendations:", "Recommendations", "colSpan12"],
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </ActiveHistoryDropDown2>
          )}
          {active === "History" && (
            <ActiveHistoryDropDown2 type={"History"}>
              {" "}
              <>
                {" "}
                {statusfinderFn("history", selectedPatient.OtherRecommendations)
                  ?.length === 0 ? (
                  <></>
                ) : (
                  <CarePlanDisplayGeneral
                    Type={"history"}
                    data={statusfinderFn(
                      "history",
                      selectedPatient.OtherRecommendations
                    )}
                    contentArray={[
                      ["Type:", "Type", "colSpan12"],
                      ["Recorded Date & Time:", "RecordedDate", "colSpan6"],
                      ["End Date & Time:", "EndDate", "colSpan6"],
                      ["Recommendations:", "Recommendations", "colSpan12"],
                    ]}
                  />
                )}
              </>
            </ActiveHistoryDropDown2>
          )}
        </div>
      </DropDownContent>
    </div>
  );
};

export default OtherRecommends;
